import { useEffect, useRef, useState } from 'react'

export function useResizeColumn(onChangeWidth?: (width: number) => void) {
  const [headerNode, setHeaderNode] = useState<HTMLElement>()
  const [resizeNode, setResizeNode] = useState<HTMLElement>()
  const onChangeWidthRef = useRef(onChangeWidth)
  onChangeWidthRef.current = onChangeWidth

  useEffect(() => {
    if (!headerNode || !onChangeWidth || !resizeNode) return

    function handleMouseDown(event: MouseEvent) {
      const startX = event.clientX
      const startWidth = headerNode.offsetWidth

      const handleMouseMove = (e: MouseEvent) => {
        const newWidth = startWidth + (e.clientX - startX)
        headerNode.style.width = `${newWidth}px`
        onChangeWidthRef.current?.(newWidth)
      }

      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove)
        document.removeEventListener('mouseup', handleMouseUp)
      }

      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
    }

    resizeNode.addEventListener('mousedown', handleMouseDown)
    return () => {
      resizeNode.removeEventListener('mousedown', handleMouseDown)
    }
  }, [resizeNode])

  return { setHeaderNode, setResizeNode }
}
