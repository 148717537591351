import { Mark } from '@tiptap/core'

export const InsertedMark = Mark.create({
  name: 'inserted',

  parseHTML() {
    return [
      {
        tag: 'ins',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['ins', HTMLAttributes, 0]
  },
})
