import moment from 'moment'

export const EPOCH = new Date(2024, 1, 1) // January 1 2024 is a monday

export type DateFormat = 'relative' | 'absolute' | string

export function formatDate(date: Date, format: DateFormat = 'relative') {
  if (format === 'absolute') {
    return `${moment(date).format('M/D/YY')} at ${moment(date).format('h:mma')}`
  }

  if (format === 'relative') {
    return moment(date).fromNow()
  }

  return moment(date).format(format)
}

export function differenceInWeeks(dateA: Date, dateB: Date): number {
  const millisecondsPerWeek = 1000 * 60 * 60 * 24 * 7 // Number of milliseconds in a week
  const differenceInMilliseconds = Math.abs(dateA.getTime() - dateB.getTime())
  return Math.floor(differenceInMilliseconds / millisecondsPerWeek)
}

export function numberOfWeeksSinceEpoch(date: Date): number {
  return differenceInWeeks(date, EPOCH)
}
