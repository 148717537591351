import { Link } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment } from 'react'

import { MarketingLayout, Section, Typography } from '../components'

import { TOCList } from './TOC'
import {
  CaseStudy,
  ChapterHeader,
  DownloadWorkbook,
  ExternalLink,
  HowToSection,
  PersonCard,
  QuoteBordered,
} from './components'

import { images, urls } from './howToConfig'

import './styles/how-to-ebook.scss'

export default () => (
  <MarketingLayout
    title='Policy Distribution and Electronic Signatures: The Accept Work'
    description={
      'A guide on how to distribute policies and procedures or an employee handbook, and best practices for collecting acknowledgement signatures from employees.'
    }
    className='how-to-ebook'
  >
    <ChapterHeader
      title='6: The Accept Work'
      subtitle='Distribution and Sign Off'
      prev={{ url: urls.pepWork, text: '5. The Pep Work' }}
      next={{ url: urls.conclusion, text: 'No More Work' }}
      img={images.headerAcceptWork.src}
    />

    <HowToSection
      className='container tw-mt-14'
      main={
        <QuoteBordered
          quote='I always tell my clients more communication is better. You want to make sure that your workforce always understands what’s going on, which is probably the biggest reason you need policies and procedures in a handbook. Plus, they are a way to communicate to employees what you really believe in.'
          attribution={{
            name: 'Teresa Bult',
            title: (
              <span>
                Administrative Partner &amp; General Counsel for the Firm at{' '}
                <ExternalLink href='https://www.constangy.com/'>
                  Constangy, Brooks, Smith & Prophete, LLP
                </ExternalLink>
              </span>
            ),
          }}
        />
      }
      side={<TOCList activeIndex={6} />}
    />

    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='p2'>
            Once your handbook is ready for distribution (woohoo!), it’s time to
            set it up for the best possible reception. You may want to solicit
            your communications or marketing team again to help build your
            communication plan. Your approach will be driven by how drastically
            the company policies have changed from the previous version.
          </Typography>
        </Fragment>
      }
    />

    <DownloadWorkbook
      text="What to say? There are email scripts a-plenty in our Workbook. If you haven't downloaded by now, you're missing out!"
      extraMarginTop='-130px'
      extraMarginBottom='-120px'
      stack={[
        images.workbook.emailTemplates,
        images.workbook.commsStrategy,
        images.workbook.distributionMethods,
      ]}
    />

    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='h3' className='dark'>
            Consider the Following Situations:
          </Typography>
          <Typography variant='p2'>
            <strong>
              Your policies have remained the same with only minimal changes,
              but you wanted to create a handbook more aligned with your company
              brand.
            </strong>
            <br />
            Great! Since there isn’t anything that’s likely to upset employees,
            you can send out an invitation to a company town hall or add an
            agenda item to a standing town hall and make the announcement to
            lots of cheers and praise.
          </Typography>
          <Typography variant='p2'>
            <strong>
              Your policies have remained the same, but some procedures have
              changed, which may cause confusion for some or all employees.
            </strong>
            <br />
            If confusion is to be experienced only by a specific group of
            people, consider starting with a town hall announcement, but offer a
            follow-up discussion for the groups most significantly impacted.
            Send invitations to discuss the changes more thoroughly with those
            people, then provide an email recap to all individuals, including a
            link to the recorded discussion for those unable to attend.
          </Typography>
          <Typography variant='p2'>
            <strong>
              Some policies have changed, and those changes may distress
              employees.
            </strong>
            <br />
            This is where the old adage, “tell them what you’re going to tell
            them, tell them, then tell them what you told them,” comes into
            play. Start with the invitation to the town hall with mandatory
            attendance. Then, during the town hall, first convey why changes
            were necessary before explaining the extent of the changes. Then,
            communicate how employees may be affected and when. Invite employees
            to discuss the changes with their managers or the hr team and
            provide an opportunity for a follow-up discussion if needed.
          </Typography>
          <Typography variant='p2'>
            Afterward, send an email recapping the discussion, providing the
            handbook for their review, and directing them to the specific area
            that covers the change. Don’t forget to remind them of the why again
            to increase their willingness to understand the need for change.
          </Typography>
        </Fragment>
      }
    />

    <CaseStudy title='Case Study: handling an unpopular revision'>
      <Typography variant='p2'>
        Cynthia knew that the revision to the company bonus policy might be
        unpopular. To ensure employees understood the reason behind the change
        and what it would mean to each one, her team provided a communication
        strategy that coincided with benefits enrollment, conducted multiple
        webinars with the opportunity for employees to ask questions, and sent
        out a reminder before the change would be visible in their next
        paycheck. They were transparent through the whole process, which
        reflected a key company value and was appreciated by the employees.
      </Typography>
      <PersonCard
        img={images.profileCynthia.src}
        name='Cynthia Gore'
        title={
          <span>
            Head of HR Operations and Talent Acquisition at{' '}
            <ExternalLink href='https://www.mbusa.com'>
              Mercedes-Benz USA
            </ExternalLink>
          </span>
        }
      />
    </CaseStudy>

    <HowToSection
      className='container tw-mt-18'
      main={
        <Fragment>
          <Typography variant='h3' className='dark'>
            Employee Acknowledgements
          </Typography>
          <Typography variant='p2'>
            When it comes to collecting sign offs on the handbook, you’ll want
            to provide enough time for employees to review, ask questions, and
            offer suggestions for improvements. It has taken a lot of time and
            effort to get to this point, so it’s natural to want to close this
            last part of the project ASAP and move on to all the other projects
            demanding your attention. However, reading and signing the handbook
            is not likely to be on the top of your employees’ priority list (no
            matter how awesome you made it), so you’ll want to provide a
            reasonable window of time.{' '}
            <strong>
              Thirty days is a common threshold for allowing employees to read,
              ask questions, and agree.
            </strong>
          </Typography>
          <Typography variant='p2'>
            If you’re distributing a print version of the handbook and
            physically collecting signatures, you may need to rely on team
            managers to ensure that their employees respond in a timely manner.
          </Typography>
          <Typography variant='p2'>
            If possible, use electronic signatures; it’s easier for everyone.
            With an electronic version of the handbook, employees can quickly
            locate information they need and reference it later. Plus, they have
            a version they can easily review when a situation arises. Adherence
            to policies and procedures is key, so it won’t help anyone if
            employees can’t find the handbook six months down the road or if the
            version they do find is outdated.
          </Typography>
          <Typography variant='p2'>
            Your company will be better protected if employees have an
            always-accessible electronic handbook that maintains records of
            employee acknowledgements. Embrace technology and make your life
            easier.
          </Typography>
        </Fragment>
      }
    />

    <Section className='container tw-p-18 text-center'>
      <Fragment>
        <Typography variant='h2'>Next Chapter: No More Work</Typography>
        <Typography variant='p2'>
          <Link className='btn btn-link tw-m-2' href={urls.pepWork}>
            <FontAwesomeIcon className='tw-mr-2' icon='arrow-left' />
            Previous
          </Link>
          <Link className='btn btn-primary tw-m-2' href={urls.conclusion}>
            Next: The Conclusion
          </Link>
        </Typography>
      </Fragment>
    </Section>
  </MarketingLayout>
)
