import React from 'react'
import { MarketingLayout } from '../../components'
import ContactSection from './ContactSection'
import DidYouKnowSection from './DidYouKnowSection'
import LightbulbSection from './LightbulbSection'
import OurMissionSection from './OurMissionSection'
import OurVisionSection from './OurVisionSection'
import WhereToFindUsSection from './WhereToFindUsSection'

export default () => (
  <MarketingLayout
    title='About Blissbook: the Culture-First Online Employee Handbook Company'
    description={
      'Our mission: inspired employees. Learn about our company and why we created culture-first policy management & communication software.'
    }
  >
    <LightbulbSection />
    <OurMissionSection />
    <DidYouKnowSection />
    <OurVisionSection />
    <ContactSection />
    <WhereToFindUsSection />
  </MarketingLayout>
)
