export type AbstractMark = {
  type: string
  attrs?: Record<string, any>
}

export type MarkStepType = 'addMark' | 'removeMark'

// FormatMark -----------------------------------------------------------------

export type BoldMark = {
  type: 'bold'
}

export type ColorMarkAttributes = {
  color: string
}

export type ColorMark = {
  type: 'color'
  attrs: ColorMarkAttributes
}

export type DeletedMark = {
  type: 'deleted'
}

export type FontSizeMarkAttributes = {
  fontSize: string
}

export type FontSizeMark = {
  type: 'fontSize'
  attrs: FontSizeMarkAttributes
}

export type InsertedMark = {
  type: 'inserted'
}

export type ItalicMark = {
  type: 'italic'
}

export type LinkMark = {
  type: 'link'
  attrs: {
    href: string
  }
}

export type UnderlineMark = {
  type: 'underline'
}

export type FormatMark =
  | BoldMark
  | ColorMark
  | DeletedMark
  | FontSizeMark
  | InsertedMark
  | ItalicMark
  | LinkMark
  | UnderlineMark

export const formatMarkTypes = [
  'bold',
  'color',
  'deleted',
  'fontSize',
  'inserted',
  'italic',
  'link',
  'underline',
]

// Mark -----------------------------------------------------------------------

export type Mark = FormatMark
