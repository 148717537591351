import config from '@blissbook/ui-config'
import { ButtonLink, FullScreenPage, Head } from '@blissbook/ui/lib'
import { useStore } from '@blissbook/ui/util/store'
import React, { Fragment, useState } from 'react'
import { AuthButton, Typography } from '../../components'
import { HelpForm } from './HelpForm'

const onReload = (event) => {
  event.preventDefault()
  window.location.reload()
}

const ErrorPageLayout = ({ children, error, heading, helpMessage, title }) => {
  const [showHelp, setShowHelp] = useState(false)
  return (
    <FullScreenPage hideBackground>
      <Head title={title} />
      <div className='container tw-p-6'>
        <Choose>
          <When condition={showHelp}>
            <HelpForm
              className='tw-mx-auto'
              css={{
                maxWidth: 600,
              }}
              initialBody={helpMessage}
              onClose={() => setShowHelp(false)}
            />
          </When>
          <Otherwise>
            <Typography className='auth tw-mb-6' variant='h1'>
              {heading}
            </Typography>

            <If condition={error}>
              <Typography className='tw-mb-6' tagName='div' variant='p2'>
                {error.message}
              </Typography>
            </If>

            {children}

            <AuthButton className='tw-mb-6' onClick={onReload}>
              Sign in
            </AuthButton>

            <Typography className='auth' variant='p2'>
              <If condition={config.intercom && helpMessage}>
                {' '}
                You can also{' '}
                <ButtonLink onClick={() => setShowHelp(true)}>
                  submit a help request
                </ButtonLink>
                .
              </If>
            </Typography>
          </Otherwise>
        </Choose>
      </div>
    </FullScreenPage>
  )
}

// SignInErrorPage ------------------------------------------------------------

const authNameByType = {
  email: 'email address',
  employeeId: 'employee ID',
  customSsoId: 'Single Sign On Identifier',
}

const getAuthName = (authType) => authNameByType[authType] || authType

const defaultErrorProps = {
  ErrorRemediation: undefined,
  heading: 'We couldn’t sign you in.',
  formatHelpMessage: undefined,
  name: undefined,
}

const errorPropsByCode = {
  EXPIRED_AUTH_TOKEN: {
    heading: 'Authentication expired',
    name: 'Expired Authentication Token',
  },
  EXPIRED_TOKEN: {
    name: 'Access Token expired',
  },
  INVALID_AUTH_TOKEN: {
    ErrorRemediation: () => (
      <Typography className='auth tw-mb-6' variant='p2'>
        Please note: When you request a token, any other tokens that have been
        requested in the past are invalidated. You may be clicking on an
        invalid/old token because emails sent to you from this system are being
        delayed. Your best course of action may be to request a new token and
        then wait up to 20 minutes for it to be delivered. If that doesn't work
        or this does not describe your issue, please submit a help request
        below.
      </Typography>
    ),
    formatHelpMessage: () =>
      `Help! I can't sign in. I'm getting an invalid authentication token error.`,
    name: 'Invalid Authentication Token',
  },
  INVALID_TOKEN: {
    formatHelpMessage: () =>
      `Help! I can't sign in. I'm getting an invalid access token error.`,
    name: 'Invalid Access Token',
  },
  PERSON_ARCHIVED: {
    ErrorRemediation: () => (
      <Typography className='auth tw-mb-6' variant='p2'>
        If you think this is a mistake, contact your HR or IT department and ask
        them to reinstate your account.
      </Typography>
    ),
    formatHelpMessage: ({ error }) =>
      `Help! I'm trying to sign in as ${error.authId}, but it's telling me that I'm no longer an active user.`,
    name: 'Person Archived',
  },
  PERSON_AUTH_CHANGED: {
    ErrorRemediation: ({
      authSettings: { singleSignOns },
      error: { authType },
    }) => (
      <Choose>
        <When condition={!singleSignOns.length}>
          <Typography className='auth tw-mb-6' variant='p2'>
            To resolve this issue, contact your HR or IT department.
          </Typography>
        </When>
        <Otherwise>
          <Typography className='auth tw-mb-6' variant='p2'>
            If your {getAuthName(authType)} recently changed, the issue is
            likely that your {getAuthName(authType)} associated with your Single
            Sign-On (SSO) provider differs from what's in this system.
          </Typography>

          <Typography className='auth tw-mb-6' variant='p2'>
            To resolve this issue, contact your HR or IT department. They may
            need to change your {getAuthName(authType)} in this system or within
            your SSO system.
          </Typography>
        </Otherwise>
      </Choose>
    ),
    formatHelpMessage: ({ error }) =>
      `Help, I can't sign in. It's telling me to try ${error.authId}, but that doesn't work.`,
    name: 'Person Auth Changed',
  },
  PERSON_NOT_FOUND: {
    ErrorRemediation: ({
      authSettings: { singleSignOns },
      error: { authType },
    }) => (
      <Choose>
        <When condition={!singleSignOns.length}>
          <Typography className='auth tw-mb-6' variant='p2'>
            To resolve this issue, contact your HR or IT department and ask them
            to create an account for you.
          </Typography>
        </When>
        <Otherwise>
          <Typography className='auth tw-mb-6' variant='p2'>
            The problem may be that your {getAuthName(authType)} associated with
            your Single Sign-On (SSO) provider differs from what's in this
            system.
          </Typography>

          <Typography className='auth tw-mb-6' variant='p2'>
            To resolve this issue, contact your HR or IT department. They may
            need to change your {getAuthName(authType)} in this system or within
            your SSO system.
          </Typography>
        </Otherwise>
      </Choose>
    ),
    formatHelpMessage: ({ error }) =>
      `Help! I'm trying to sign in as ${
        error.authId
      }, but it's telling me that my ${
        authNameByType[error.authType]
      } was not found.`,
    name: 'Person Not Found',
  },
  RESET_SESSION: {
    heading: 'You are no longer signed in.',
    name: 'Reset Session',
  },
  UNKNOWN_SSO_ERROR: {
    formatHelpMessage: () =>
      `Help! I'm trying to sign in, but it's telling me that there was an unexpected error.`,
    nane: 'Unknown SSO Error',
  },
}

export const SignInErrorPage = () => {
  const { authSettings, error } = useStore()

  // Get error-specific properties
  const { ErrorRemediation, formatHelpMessage, heading, name } = {
    ...defaultErrorProps,
    ...errorPropsByCode[error.code],
  }

  // Format any help message
  const helpMessage = formatHelpMessage?.({ error })

  // Format the title with the error name (if present)
  let title = 'Sign In Error'
  if (name) title += `: ${name}`

  return (
    <ErrorPageLayout
      error={error}
      heading={heading}
      helpMessage={helpMessage}
      message={error.message}
      title={title}
    >
      <If condition={ErrorRemediation}>
        <ErrorRemediation authSettings={authSettings} error={error} />
      </If>
    </ErrorPageLayout>
  )
}

// SignedOutPage --------------------------------------------------------------

export const SignedOutPage = () => {
  const { error } = useStore()
  return <ErrorPageLayout heading={error.message} title='Signed Out' />
}
