import isEqual from 'lodash/isEqual'
import isString from 'lodash/isString'
import pick from 'lodash/pick'
import { useMemo } from 'react'
import { type ColumnKey, stringifyColumnKey } from './ColumnKey'

export type ColumnState<KeyType = string> = ColumnKey<KeyType> & {
  hidden?: boolean
  width?: number
}

export function pickColumnState<KeyType = string>(
  column: ColumnState<KeyType>,
): ColumnState<KeyType> {
  return pick(column, ['field', 'path', 'hidden', 'width'])
}

export function parseColumnState<KeyType = string>(
  value: ColumnState<KeyType> | string,
): ColumnState<KeyType> {
  return isString(value) ? { field: value as KeyType } : value
}

export function areColumnsEqual<KeyType = string>(
  lhs: ColumnState<KeyType>[] | undefined,
  rhs: ColumnState<KeyType>[] | undefined,
) {
  if (!lhs || !rhs) return lhs === rhs
  return isEqual(lhs.map(pickColumnState), rhs.map(pickColumnState))
}

export function orderColumns<
  Column extends ColumnState<KeyType>,
  KeyType = string,
>(columns: Column[], columnStates: ColumnState<KeyType>[]) {
  if (!columnStates)
    return columns.map((column) => ({
      ...column,
      hidden: column.hidden || false,
    }))

  const columnsByKey = new Map(
    columns.map((column) => [stringifyColumnKey(column), column]),
  )

  // Map column keys to columns
  const remainingColumns = [...columns]
  const orderedColumns: Column[] = []
  for (const columnState of columnStates) {
    // Try to find the column
    const key = stringifyColumnKey(columnState)
    const column = columnsByKey.get(key)
    if (!column) continue

    // Add to the list
    orderedColumns.push({
      ...column,
      hidden: columnState.hidden ?? (column.hidden || false),
      width: columnState.width || column.width || undefined,
    })

    // Remove from the remaining list
    remainingColumns.splice(remainingColumns.indexOf(column), 1)
  }

  // Add remaining columns
  for (const column of remainingColumns) {
    orderedColumns.push({ ...column, hidden: true })
  }

  return orderedColumns
}

export function useOrderedColumns<
  Column extends ColumnState<KeyType> & { hidden?: boolean },
  KeyType = string,
>(columns: Column[], columnKeys: ColumnState<KeyType>[]) {
  return useMemo(() => orderColumns(columns, columnKeys), [columns, columnKeys])
}
