import './SortHeader.scss'
import { useResizeColumn } from '@blissbook/ui/hooks/useResizeColumn'
import { mergeRefs } from '@blissbook/ui/util'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React from 'react'
import { HeadingDivider } from '../table/layout'
import { type SortOrder, getNextSortOrder, parseSortOrder } from './SortOrder'

export const SortHeader = React.forwardRef<
  HTMLDivElement,
  {
    canRemove?: boolean // Can the user remove the sort order via triple-click?
    children: React.ReactNode
    className?: string
    columnOrder: SortOrder | string
    onChangeOrder: (order: SortOrder) => void
    onChangeWidth?: (width: number) => void
    order: SortOrder
    style?: React.CSSProperties
  }
>(
  (
    {
      canRemove,
      children,
      className,
      columnOrder,
      onChangeOrder,
      onChangeWidth,
      order,
      style,
    },
    ref,
  ) => {
    const { setHeaderNode, setResizeNode } = useResizeColumn(onChangeWidth)
    const $columnOrder = parseSortOrder(columnOrder)
    const isActive =
      $columnOrder.field === order?.field && $columnOrder.path === order?.path

    function handleNextSortOrder() {
      const nextSortOrder = getNextSortOrder($columnOrder, order, canRemove)
      onChangeOrder(nextSortOrder)
    }

    return (
      <>
        <div
          className={classnames('sort-header', className)}
          onClick={handleNextSortOrder}
          onKeyUp={(event) => {
            if (event.key === 'Enter') handleNextSortOrder()
          }}
          ref={mergeRefs([ref, setHeaderNode])}
          style={style}
        >
          <div className='ellipsis'>{children}</div>
          <If condition={isActive}>
            <SortIcon className='tw-ml-1' desc={order.direction === 'desc'} />
          </If>
        </div>
        {onChangeWidth && <HeadingDivider ref={setResizeNode} />}
      </>
    )
  },
)

export const SortIcon: React.FC<{
  className?: string
  desc?: boolean
}> = ({ desc, ...props }) => (
  <FontAwesomeIcon
    {...props}
    css={{
      display: 'inline-block',
      transform: desc ? 'rotate(180deg)' : undefined,
      transition: 'all 160ms linear',
    }}
    icon='caret-down'
  />
)
