import { useIsomorphicLayoutEffect } from '@blissbook/ui/util/hooks'
import { logUIError } from '@blissbook/ui/util/integrations/sentry'
import last from 'lodash/last'
import without from 'lodash/without'
import { nanoid } from 'nanoid'
import { useCallback, useRef, useState } from 'react'
import type {
  InfiniteProps,
  InfiniteState,
  LoadMoreFunction,
} from '../InfiniteContext'

function createInitialState(): InfiniteState {
  return {
    key: nanoid(),
    isLoaded: false,
    isLoading: true,
    rows: [],
  }
}

/** Hook to manage state for infinite scrolling */
export function useInfinite(
  loadMore: LoadMoreFunction,
  pageSize: number,
  deps: any[],
): InfiniteProps {
  const ref = useRef(createInitialState())
  const [, setResetStateAt] = useState<Date>()

  // Handler to go to a new state
  const setState = (state: InfiniteState) => {
    ref.current = state
    setResetStateAt(new Date())
  }

  // Handle to update the current state
  const updateState = (changes: Partial<InfiniteState>) =>
    setState({ ...ref.current, ...changes })

  // When any dependencies change, restart
  useIsomorphicLayoutEffect(() => {
    setState(createInitialState())
    loadNext().catch(logUIError)
  }, deps)

  // Load the next rows
  const loadNext = async () => {
    const { key, rows } = ref.current
    const lastRow = last(rows)
    const newRows = await loadMore(lastRow)

    // If we aborted the query, the rows will be different and stop
    if (key !== ref.current.key) return

    // Update the state
    updateState({
      rows: rows ? [...rows, ...newRows] : newRows,
      isLoading: false,
      isLoaded: newRows.length < pageSize,
    })
  }

  // Callback when we need to load more
  const onLoadMore = useCallback(async () => {
    updateState({ isLoading: true })
    await loadNext()
  }, deps)

  // Pure handler for updating a row
  const updateRow = useCallback((row, changes) => {
    const rows = [...ref.current.rows]
    const index = rows.indexOf(row)
    rows[index] = { ...row, ...changes }
    updateState({ rows })
  }, [])

  // Pure handler for removing a row
  const removeRow = useCallback((row) => {
    const { rows } = ref.current
    updateState({
      rows: without(rows, row),
    })
  }, [])

  return {
    ...ref.current,
    onLoadMore,
    removeRow,
    updateRow,
  }
}
