import type {
  Property,
  PropertyValueInput,
  SelectPropertyValue,
} from '@blissbook/lib/properties'
// @ts-ignore: WIP imports
import { SearchInput } from '@blissbook/ui/lib'
import { omitTypename } from '@blissbook/ui/lib/graphql'
import { handleError } from '@blissbook/ui/util/errors'
import { nanoid } from 'nanoid'
import React, { useState } from 'react'
import type { UpdatePropertyFunction } from '../PropertyEditor'
import { PropertyOptionView } from '../PropertyOptionView'
import type { PropertyValueEditorProps } from './PropertyValueEditor'

type SelectOption = {
  key: string
  label: string
  text?: string
  value?: string
}

export function SelectPropertyValueEditor({
  onChange,
  property,
  updateProperty,
  value,
}: PropertyValueEditorProps & {
  onChange: (value: PropertyValueInput | null) => void
  property: Property
  updateProperty: UpdatePropertyFunction
  value: SelectPropertyValue | null
}) {
  const { allowMultiple, copyToLinkedOrganization, options } = property
  const ids = value?.ids || []
  const activeOptions = options.filter((option) => ids.includes(option.id))
  const activeIds = activeOptions.map((option) => option.id)
  const canAdd = allowMultiple || !activeOptions.length
  const [isSubmitting, setSubmitting] = useState(false)

  async function getOptions(text: string) {
    const textLower = text.toLowerCase()

    const searchOptions: SelectOption[] = options
      .filter(
        (option) =>
          option.label.toLowerCase().includes(textLower) &&
          !ids.includes(option.id),
      )
      .map((option) => ({
        label: option.label,
        key: option.id,
        value: option.id,
      }))

    const hasProperty = options.some(
      (option) => option.label.toLowerCase() === textLower,
    )
    if (text.length && !hasProperty && updateProperty) {
      searchOptions.unshift({
        label: `Add "${text}"`,
        key: text,
        text,
        value: undefined,
      })
    }

    return searchOptions
  }

  /** Create a new option and then add the new value */
  async function handleCreateOption(label: string) {
    setSubmitting(true)

    try {
      const newOption = {
        label,
        id: nanoid(),
      }

      const { allowMultiple, options } = property
      await updateProperty(property.id, {
        allowMultiple,
        copyToLinkedOrganization,
        label: property.label,
        options: [...options.map(omitTypename), newOption],
      })

      await onChange({ ids: [...activeIds, newOption.id] })
    } catch (error) {
      handleError(error)
    }

    setSubmitting(false)
  }

  async function handleAddOption(optionId: string) {
    setSubmitting(true)
    await onChange({ ids: [...activeIds, optionId] })
    setSubmitting(false)
  }

  async function handleRemoveOption(optionId: string) {
    setSubmitting(true)
    const newIds = activeIds.filter((id) => id !== optionId)
    await onChange(newIds.length ? { ids: newIds } : null)
    setSubmitting(false)
  }

  return (
    <div className='tw-inline-flex tw-gap-1 tw-flex-wrap'>
      {activeOptions.map((option) => (
        <PropertyOptionView
          key={option.id}
          onRemove={() => handleRemoveOption(option.id)}
          option={option}
        />
      ))}

      {canAdd && (
        <SearchInput
          autoFocus
          disabled={isSubmitting}
          getOptions={getOptions}
          inputClassName='form-control-sm tw-border-none tw-bg-white'
          key={ids.join('-')}
          minLength={0}
          onSelect={async (option: SelectOption) => {
            const { text, value } = option
            if (value) {
              await handleAddOption(value)
            } else if (text) {
              await handleCreateOption(text)
            }
          }}
          placeholder={
            isSubmitting
              ? `Adding "${property.label}"...`
              : `Add ${property.label}...`
          }
          searchIcon={false}
          style={{ width: 280 }}
        />
      )}
    </div>
  )
}
