import {
  PropertyTargetType,
  PropertyType,
  type WhodunnitPerson,
} from '@blissbook/ui/application/graph/types-and-hooks'
export { PropertyTargetType, PropertyType }
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCalendarDays } from '@fortawesome/pro-regular-svg-icons/faCalendarDays'

export type PropertyTypeMetadata = {
  allowBulkEdit?: boolean
  allowMultiple?: boolean
  hasOptions?: boolean
  icon: IconProp
  label: string
}

export const propertyTypes: Record<PropertyType, PropertyTypeMetadata> = {
  date: {
    allowBulkEdit: true,
    icon: faCalendarDays,
    label: 'Date',
  },
  number: {
    allowBulkEdit: true,
    icon: ['far', 'input-numeric'],
    label: 'Number',
  },
  person: {
    allowBulkEdit: true,
    allowMultiple: true,
    icon: ['far', 'user'],
    label: 'Person',
  },
  select: {
    allowBulkEdit: true,
    allowMultiple: true,
    hasOptions: true,
    icon: ['far', 'circle-caret-down'],
    label: 'Select',
  },
  text: {
    icon: ['far', 'text'],
    label: 'Text',
  },
}

export type PropertyOption = {
  id: string
  label: string
  color?: string
}

export type Property = {
  id: string
  targetType: PropertyTargetType
  type: PropertyType
  label: string
  allowMultiple: boolean
  copyToLinkedOrganization: boolean
  options?: PropertyOption[]
  archivedAt?: string
}

export type PersonPropertyValueInput = {
  personIds: number[]
}

export type NumberPropertyValueInput = {
  number: number
}

export type NumberPropertyValue = NumberPropertyValueInput & {
  propertyId: string
}

export type PersonPropertyValue = PersonPropertyValueInput & {
  propertyId: string
  people: WhodunnitPerson[]
}

export type SelectPropertyValueInput = {
  ids: string[]
}

export type SelectPropertyValue = SelectPropertyValueInput & {
  propertyId: string
}

export type TextPropertyValueInput = {
  text: string
}

export type TextPropertyValue = TextPropertyValueInput & {
  propertyId: string
}

export type DatePropertyValueInput = {
  date: string | null // ISO date string
}

export type DatePropertyValue = {
  propertyId: string
  date: string | null
}

export type PropertyValueInput =
  | DatePropertyValueInput
  | NumberPropertyValueInput
  | PersonPropertyValueInput
  | SelectPropertyValueInput
  | TextPropertyValueInput

export type PropertyValue =
  | DatePropertyValue
  | NumberPropertyValue
  | PersonPropertyValue
  | SelectPropertyValue
  | TextPropertyValue

export type CreatePropertyInput = Omit<
  Property,
  'id' | 'copyToLinkedOrganization'
>
export type UpdatePropertyInput = Omit<Property, 'id' | 'type' | 'targetType'>
