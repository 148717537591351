import { cx } from '@emotion/css'
import React from 'react'
import { ShapeGrid } from '../shapes'

const shapes = [
  { x: 1, y: 0, type: 'square', color: 'purple-100', fill: true, rotate: 90 },
  { x: 2, y: 1, type: 'square', color: 'purple-300', fill: true, rotate: 90 },
  { x: 2, y: 2, type: 'wedge', color: 'purple-500', fill: true },
  { x: 3, y: 2, type: 'square', color: 'red-500', fill: true },

  { x: 1, y: 3, type: 'wedge', color: 'aqua-500', fill: true, rotate: 180 },
  { x: 2, y: 3, type: 'square', color: 'blurple-500', fill: true },
  { x: 3, y: 3, type: 'quotes', color: 'sunshine-500', scale: 0.6 },

  { x: 0, y: 4, type: 'wedge', color: 'sunshine-300', fill: true },
  { x: 1, y: 4, type: 'square', color: 'sunshine-500', fill: true },
  { x: 2, y: 4, type: 'square', color: 'red-500', fill: true },
  { x: 3, y: 4, type: 'wedge', color: 'aqua-500', fill: true, rotate: 180 },
]

const shapeSize = 40

export type EmptyStateProps = {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  title?: string
}

export function EmptyState({
  children,
  className,
  style,
  title,
}: EmptyStateProps) {
  return (
    <div
      className={cx('tw-flex tw-flex-col tw-items-center', className)}
      style={style}
    >
      <ShapeGrid
        shapes={shapes}
        shapeSize={shapeSize}
        style={{ height: shapeSize * 5, width: shapeSize * 4 }}
      />

      <div className='tw-font-semibold tw-text-lg tw-mt-4'>{title}</div>
      <div className='tw-text-gray-700 tw-text-sm tw-mt-2'>{children}</div>
    </div>
  )
}
