import { getColor, gridBreakpoints } from '@blissbook/ui/branding'
import {
  BackgroundShapeGrid,
  Col,
  ForwardLink,
  Row,
  gridShapeSize,
} from '@blissbook/ui/lib'
import { css } from '@emotion/react'
import isString from 'lodash/isString'
import React from 'react'
import { Accordion, HeadingDivider, Typography } from '../../components'

export default ({
  accordionFeatures,
  background,
  color,
  features,
  headingColor,
  maxColumns = 2,
  shapeType,
  title,
  titleOffset = 1,
  topLeftShapes,
  topRightShapes,
  ...props
}) => (
  <div
    {...props}
    className='container pt-sm'
    css={css`
      position: relative;
      @media (min-width: ${gridBreakpoints.lg}px) {
        padding-top: ${gridShapeSize * titleOffset}px !important;
      }
      @media (min-width: ${gridBreakpoints.xl}px) {
        padding-left: 150px !important;
        padding-right: 150px !important;
      }
    `}
  >
    <If condition={topLeftShapes}>
      <BackgroundShapeGrid
        className='tw-hidden lg:tw-block'
        alignX='left'
        alignY='top'
        shapes={topLeftShapes}
      />
    </If>

    <If condition={topRightShapes}>
      <BackgroundShapeGrid
        className='tw-hidden lg:tw-block'
        alignX='right'
        alignY='top'
        shapes={topRightShapes}
      />
    </If>

    {background}

    <div
      css={css`
        position: relative;
        @media (max-width: ${gridBreakpoints.lg - 1}px) {
          padding-top: ${gridShapeSize * 1.5}px;
        }
        @media (min-width: ${gridBreakpoints.lg}px) {
          background: none;
        }
      `}
    >
      <BackgroundShapeGrid
        className='lg:tw-hidden'
        css={{ zIndex: 0 }}
        alignX='right'
        alignY='top'
        shapes={[
          {
            x: 0,
            y: 0,
            type: 'wedge',
            color: `${color.split('-')[0]}-300`,
            rotate: -90,
            fill: true,
          },
          {
            x: 0,
            y: 0,
            type: shapeType,
            color,
            translateX: -gridShapeSize / 2,
            translateY: gridShapeSize / 2,
          },
        ]}
      />

      <Typography variant='h1' size='lg' className='lg:tw-mb-18'>
        {title}
      </Typography>

      <Row>
        {features.map((feature, index) => (
          <Col {...props} key={index} md={12 / maxColumns} className='tw-mb-10'>
            <div
              css={css`
                > :last-child {
                  margin-bottom: 0;
                }

                @media (min-width: ${gridBreakpoints.lg}px) {
                  max-width: 400px;
                }
              `}
            >
              <Choose>
                <When condition={React.isValidElement(feature.heading)}>
                  <div className='tw-mb-6'>{feature.heading}</div>
                  <Typography variant='h2'>{feature.title}</Typography>
                </When>
                <When condition={isString(feature.heading)}>
                  <Typography
                    variant='h4'
                    css={{ color: getColor(headingColor || color) }}
                  >
                    {feature.heading}
                  </Typography>
                  <Typography variant='h2'>{feature.title}</Typography>
                </When>
                <Otherwise>
                  <HeadingDivider variant='h2' divider={{ color }}>
                    {feature.title}
                  </HeadingDivider>
                </Otherwise>
              </Choose>

              <Typography variant='p2'>{feature.description}</Typography>

              <If condition={feature.link}>
                <ForwardLink {...feature.link} />
              </If>
            </div>
          </Col>
        ))}
      </Row>

      <If condition={accordionFeatures}>
        {accordionFeatures.map((feature, index) => (
          <Accordion key={index} size='lg' title={feature.title}>
            {feature.description}
          </Accordion>
        ))}
      </If>
    </div>
  </div>
)
