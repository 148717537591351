import { useCallback, useMemo } from 'react'
import { patchQuery } from '../util/history'
import { useQuery } from './useQuery'

/** Hook for a query parameter's state */
export function useQueryParam<T extends string>(key: string, defaultValue?: T) {
  const query = useQuery()
  const value = key in query ? query[key] : defaultValue

  const setValue = useCallback(
    (newValue: T | null | undefined) => patchQuery({ [key]: newValue }),
    [key],
  )

  return [value as T, setValue] as const
}
