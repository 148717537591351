// TODO: Sync this with styles/_variables.scss somehow
import { type Color, colors } from './styles/colors'
export * from './styles/colors'

export const themeColors = {
  primary: colors['blurple-500'],
  secondary: colors['gray-600'],
  success: colors['green-800'],
  info: colors['aqua-500'],
  warning: colors['sunshine-700'],
  danger: colors['red-700'],
  light: colors['gray-100'],
  dark: colors['gray-800'],

  aqua: colors['aqua-500'],
  green: colors['green-500'],
  purple: colors['purple-500'],
  red: colors['red-500'],
}

export const fontFamily = 'Sofia Pro,Geneva,Tahoma,sans-serif'
export const fontFamilySignature = 'Bilbo Swash Caps, cursive'

export const getColor = (color: string) => colors[color as Color] || color

export const gridBreakpoints: Record<string, number> = {
  xs: 0,
  sm: 480,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
}
