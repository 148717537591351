// IMPORTANT: THIS FILE IS IN TWO PROJECTS. TO MODIFY THIS FILE:
// Go to the blissbook project
// Make changes
// Copy the changes to the blissbook-marketing project
export const customerImagesFolder = '/images/customers'

export const TESTIMONIALS = [
  {
    id: 'cengage',
    color: '#0085CA',
    company: 'Cengage',
    companyUrl: 'https://www.cengage.com',
    handbookImageUrl: `${customerImagesFolder}/cengage/cengage-handbook.jpg`,
    isMobile: true,
    logoUrl: `${customerImagesFolder}/cengage/cengage-logo.png`,
    name: 'Amy Turner',
    photoUrl: `${customerImagesFolder}/cengage/amy.jpg`,
    quote:
      'Investing in Blissbook and the Trailblazer’s Guide is a decision we’ve never second-guessed. The Guide looks awesome, employees love it, and the process to collect and audit employee signatures (now electronically!) has never been easier. A major improvement and advancement for employee handbooks!',
    title: 'Director, HR Operations',
  },
  {
    id: 'slalom',
    color: '#0C62FB',
    company: 'Slalom',
    companyUrl: 'https://slalom.com',
    handbookImageUrl: `${customerImagesFolder}/slalom/slalom.jpg`,
    isMobile: true,
    logoUrl: `${customerImagesFolder}/slalom/slalom-logo.svg`,
    name: 'Mel Murphy',
    photoUrl: `${customerImagesFolder}/slalom/mel.jpg`,
    quote:
      'Blissbook went above and beyond our already high expectations, responded with kindness to no less than a million questions, phone calls (some just for emotional support), emails, late nights, customization and design requests, and all with an extremely tight deadline. From beginning to execution to post-launch, support has been incredible. The result of this hard work is a beautiful global handbook we are so proud of and that is very Slalom.',
    title: 'Policy and Compliance Program Manager, Global People Team',
  },
  {
    id: 'ashley',
    color: '#F8840D',
    company: 'Ashley Furniture Industries',
    companyUrl: 'https://ashleyfurniture.com',
    handbookImageUrl: `${customerImagesFolder}/ashley/ashley-handbook.jpg`,
    logoUrl: `${customerImagesFolder}/ashley/ashley-logo.png`,
    name: 'Kris Cegielski',
    photoUrl: `${customerImagesFolder}/ashley/kris.jpg`,
    quote:
      'Blissbook has been an outstanding partner when creating our first electronic handbook. Going into the process, we knew we needed help coding and organizing all of the different polices for our employees. They assisted with building the content, but more importantly taught us how to continuously update the content ourselves. When new challenges arose that might hinder us, they came through with a solution to make our handbook even better!',
    title: 'VP HR Business Partner',
  },
  {
    id: 'abb',
    color: '#ff000f',
    company: 'ABB',
    companyUrl: 'https://global.abb/group/en/about',
    handbookImageUrl: `${customerImagesFolder}/abb/abb-handbook.jpg`,
    isMobile: true,
    logoUrl: `${customerImagesFolder}/abb/abb-logo.svg`,
    name: 'Amy Garlick',
    photoUrl: `${customerImagesFolder}/abb/amy-garlick.jpg`,
    quote:
      'The thought of creating a digital handbook was overwhelming considering I’m not tech-savvy. With Blissbook, it couldn’t have been easier. The creative team was terrific to work with and I felt completely supported along the way! The finished handbook looks professional and is easy to navigate and update. What a great resource we now have for our employees!',
    title: 'Senior Manager, Employee Relations',
  },
  {
    id: 'swbc',
    color: '#28334A',
    company: 'SWBC',
    companyUrl: 'https://www.swbc.com',
    handbookImageUrl: `${customerImagesFolder}/swbc/swbc-handbook.jpg`,
    isMobile: true,
    logoUrl: `${customerImagesFolder}/swbc/swbc-logo.svg`,
    name: 'Mystel Duke',
    photoUrl: `${customerImagesFolder}/swbc/mystel.jpg`,
    quote: `Blissbook replaced our custom in-house policy management platform. They built custom features in a timely manner and we love how easy it is to update content, communicate changes with employees, and report on acknowledgements. We couldn't be happier!`,
    title: 'Senior Vice President, Human Resources',
  },
  {
    id: 'accolade',
    color: '#279E92',
    company: 'Accolade',
    companyUrl: 'https://www.accolade.com',
    handbookImageUrl: `${customerImagesFolder}/accolade/accolade-handbook-2.jpg`,
    logoUrl: `${customerImagesFolder}/accolade/accolade-logo.png`,
    name: 'Laura Janssen',
    photoUrl: `${customerImagesFolder}/accolade/laura.jpg`,
    quote:
      'Culture is so important for us at Accolade and it’s important for our employee handbook to truly reflect that. Blissbook made it so easy. The handbook is engaging for our tech-savvy employees and we never have to worry if we have the documentation we need.',
    title: 'Manager, HR Processes & Analytics',
  },
  {
    id: 'rra',
    color: '#002E5D',
    company: 'Russell Reynolds Associates',
    companyUrl: 'https://www.russellreynolds.com',
    handbookImageUrl: `${customerImagesFolder}/rra/rra-handbook.jpg`,
    logoUrl: `${customerImagesFolder}/rra/rra-logo.png`,
    name: 'Keesha-Ann Murphy',
    photoUrl: `${customerImagesFolder}/rra/keesha-ann.jpg`,
    quote:
      'We’re so glad we decided to work with Blissbook when we replaced our PDF handbook. Yes, the product is great. More importantly, when we need help, they make us feel like we’re their only customer.',
    title: 'Human Capital Generalist',
  },
  {
    id: 'caspers',
    color: '#B8232F',
    company: 'Caspers',
    companyUrl: 'https://www.casperscompany.com',
    handbookImageUrl: `${customerImagesFolder}/caspers/caspers-handbook.jpg`,
    logoUrl: `${customerImagesFolder}/caspers/caspers-logo.png`,
    name: 'Kim Scott',
    photoUrl: `${customerImagesFolder}/caspers/kim.jpg`,
    quote:
      'Putting our employee manual online has been a game changer. The ability to connect with employees across 64 worksites and provide searchable, attractive, and easy-to-use content on a mobile-friendly platform like Blissbook is a great advantage. I highly recommend.',
    title: 'VP Human Resources',
  },
  {
    id: 'daxko',
    color: '#70B6B2',
    company: 'Daxko',
    companyUrl: 'https://daxko.com',
    handbookImageUrl: `${customerImagesFolder}/daxko/daxko-handbook.jpg`,
    logoUrl: `${customerImagesFolder}/daxko/daxko-logo.png`,
    name: 'Concetta Lewis',
    photoUrl: `${customerImagesFolder}/daxko/concetta.jpg`,
    quote:
      'We love how easy it is to update our Team Member Handbook and the annotations feature really simplifies communicating changes with employees. Support is great and it’s nice how Blissbook is always improving!',
    title: 'Director of People Development',
  },
  {
    id: 'blueprism',
    color: '#0F7DC2',
    company: 'Blue Prism',
    companyUrl: 'https://www.blueprism.com',
    handbookImageUrl: `${customerImagesFolder}/blueprism/blueprism-handbook.jpg`,
    logoUrl: `${customerImagesFolder}/blueprism/blueprism-logo.png`,
    name: 'Lindsey Kucharski',
    photoUrl: `${customerImagesFolder}/blueprism/lindsey.jpg`,
    quote:
      'Our People Book is a simple, interactive home base for all our global HR policies. It’s easy for staff all over the world to access privately and we love how we can update it ourselves, anytime we want. We highly recommend Blissbook!',
    title: 'HR Manager, US & LATAM',
  },
  {
    id: 'actnano',
    color: '#2391F6',
    company: 'ActNano',
    companyUrl: 'https://actnano.com',
    handbookImageUrl: `${customerImagesFolder}/actnano/actnano-handbook.jpg`,
    logoUrl: `${customerImagesFolder}/actnano/actnano-logo.png`,
    name: 'Judi Martens',
    photoUrl: `${customerImagesFolder}/actnano/judi-martens.jpg`,
    quote: `When I needed a modern, fun and interactive handbook, Blissbook really came through. Not only is the product and platform great, the team made sure everything flowed smoothly throughout the project and provides exceptional support. We are in year two now and this was by far the best decision I've made. Easy to update and stay HR compliant with the tool.`,
    title: 'People, Culture and Office Manager',
  },
  {
    id: 'wd40',
    color: '#133C8B',
    company: 'WD-40 Company',
    companyUrl: 'https://www.wd40company.com',
    handbookImageUrl: `${customerImagesFolder}/wd40/wd40-handbook.jpg`,
    isMobile: true,
    logoUrl: `${customerImagesFolder}/wd40/wd40-logo.png`,
    name: 'Roxanne Casebier',
    photoUrl: `${customerImagesFolder}/wd40/roxanne.jpg`,
    quote:
      'We went from first contact with Blissbook to launching a beautiful, on-brand handbook in 6 weeks... over the holidays! The support team is always there for us when we need help and we’ve gotten lots of kudos from employees. Blissbook is awesome.',
    title: 'Director, Global Learning and Development',
  },
]

export type Testimonial = (typeof TESTIMONIALS)[number]
