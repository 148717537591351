import type { XYCoord } from 'dnd-core'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

export interface DragItem {
  index: number
  id: string
}

export function useSortable({
  disabled,
  id,
  index,
  onDrop,
  onMove,
  type,
}: {
  disabled?: boolean
  id: string
  index: number
  onDrop: (item: DragItem) => void
  onMove: (dragIndex: number, hoverIndex: number) => void
  type: string
}) {
  const ref = useRef<HTMLDivElement>(null)
  const [, drop] = useDrop<DragItem, void, void>({
    accept: type,
    async drop(item) {
      await onDrop(item)
    },
    hover(item, monitor) {
      if (!ref.current) return
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) return

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return

      // Time to actually perform the action
      onMove(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    canDrag: !disabled,
    type,
    item: () => ({ id, index }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    isDragging: (monitor: any) => id === monitor.getItem().id,
  })

  drag(drop(ref))

  return {
    isDragging,
    ref,
  }
}
