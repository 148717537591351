import range from 'lodash/range'
import pluralize from 'pluralize'
import { billingConfig } from './config'

const { daysPerYear, minCredits, monthsPerYear } = billingConfig
billingConfig.daysPerMonth = daysPerYear / monthsPerYear
const { daysPerMonth } = billingConfig

export const getTermLabel = (months: number) => {
  const { monthsPerYear } = billingConfig
  return months % monthsPerYear === 0
    ? pluralize('Year', months / 12, true)
    : pluralize('Month', months, true)
}

// People Count -------------------------------------------------------------

// Calculate the number of people after this number of months
// NOTE: Compound Monthly: A = P(1+rate)^months
// http://www.calculatorsoup.com/calculators/financial/compound-interest-calculator.php
export function calculatePeopleCount(
  startCount: number,
  months: number,
  monthlyRate: number,
) {
  const peopleCount = Math.round(startCount * (1 + monthlyRate) ** months)
  return Math.max(peopleCount, 1)
}

// Calculate the number of people after this number of days
export function calculatePeopleCountByDays(
  startCount: number,
  days: number,
  monthlyRate: number,
) {
  return calculatePeopleCount(startCount, days / daysPerMonth, monthlyRate)
}

// Growth Rate --------------------------------------------------------------

// Convert config growth rates to monthly
billingConfig.monthlyGrowthRate = convertGrowthRateFromYearlyToMonthly(
  billingConfig.yearlyGrowthRate,
)
billingConfig.minMonthlyGrowthRate = convertGrowthRateFromYearlyToMonthly(
  billingConfig.minYearlyGrowthRate,
)
billingConfig.maxMonthlyGrowthRate = convertGrowthRateFromYearlyToMonthly(
  billingConfig.maxYearlyGrowthRate,
)

// Convert monthly growth rate to yearly growth rate
export function convertGrowthRateFromMonthlyToYearly(
  monthlyGrowthRate: number,
) {
  return (1 + monthlyGrowthRate) ** monthsPerYear - 1
}

// Convert monthly growth rate to yearly growth rate
export function convertGrowthRateFromYearlyToMonthly(yearlyGrowthRate: number) {
  return (1 + yearlyGrowthRate) ** (1 / monthsPerYear) - 1
}

// round the growth rate
function roundGrowthRate(growthRate: number) {
  const factor = 10 ** 16
  return Math.round((growthRate + Number.EPSILON) * factor) / factor
}

// Calculate the growth rate for this many months
export function calculateMonthlyGrowthRate(
  startCount: number,
  months: number,
  endCount: number,
) {
  const monthlyRate = (endCount / startCount) ** (1 / months) - 1
  return roundGrowthRate(monthlyRate)
}

// Calculate the growth rate for this many days
export function calculateMonthlyGrowthRateByDays(
  startCount: number,
  days: number,
  endCount: number,
) {
  return calculateMonthlyGrowthRate(startCount, days / daysPerMonth, endCount)
}

// Credits ------------------------------------------------------------------

// Credit Actions

export enum CreditActionType {
  Purchase = 'purchase',
  Gift = 'gift',
  Referral = 'referral',
  SLA = 'sla',
  Deduction = 'deduction',
  Reset = 'reset',
}

export const creditActions = [
  {
    type: CreditActionType.Purchase,
    label: 'Purchase',
    allowManual: true,
  },
  {
    type: CreditActionType.Gift,
    label: 'Gift',
    allowManual: true,
  },
  {
    type: CreditActionType.Referral,
    label: 'Referral',
    allowManual: true,
  },
  {
    type: CreditActionType.SLA,
    label: 'SLA',
    allowManual: true,
  },
  {
    type: CreditActionType.Deduction,
    label: 'Deduction',
    allowManual: true,
    format: (entry: any) => {
      let users: any = -entry.delta
      if (users === minCredits) users = '≤' + users
      return 'Deduction for ' + users + ' users'
    },
  },
  {
    type: CreditActionType.Reset,
    label: 'Reset',
  },
] as const

// Calculate the daily credits (assume linear growth)
// startCount = number of people to start with
// months = number of months to calculate for
// endCount = number of people to end with
export function calculateCredits(
  startCount: number,
  months: number,
  endCount: number,
) {
  // Must have all parameters
  if (!startCount || !months || !endCount) return

  // Determine the equation to run
  const days = months * daysPerMonth
  const fullDays = Math.floor(days)
  const slope = (endCount - startCount) / (fullDays - 1)
  const daysRange = range(0, fullDays)

  // Determine spillover for the last day
  const lastExtra = days - fullDays
  const lastIndex = fullDays - 1

  // Sum the credits for every day, keeping a minCredits floor
  return daysRange
    .map((index) => {
      const peopleCount = slope * index + startCount
      const factor = index === lastIndex ? 1 + lastExtra : 1
      const credits = Math.max(peopleCount, minCredits) * factor
      return Math.round(credits)
    })
    .reduce((total, credits) => total + credits, 0)
}

// Calculate the price for these daily credits
// credits = number of credits to purchase
// pricePerCredit = price per credit
export function calculatePrice(credits: number, pricePerCredit: number) {
  // Must have all parameters
  if (!credits || !pricePerCredit) return

  const price = credits * pricePerCredit
  return Math.round(price * 10) / 10
}

// Calculate the number of days left
// credits = number of credits left
// peopleCount = price per credit
export function calculateDaysLeft(credits: number, peopleCount: number) {
  const dailyCredits = Math.max(peopleCount, minCredits)
  return Math.floor(credits / dailyCredits)
}
