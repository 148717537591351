import { getColor } from '@blissbook/ui/branding'
import {
  BackgroundImage,
  BackgroundShapeGrid,
  Button,
  Col,
  Field,
  Row,
  Shape,
} from '@blissbook/ui/lib'
import { handleError } from '@blissbook/ui/util/errors'
import { submitForm } from '@blissbook/ui/util/integrations/intercom'
import { addToast } from '@blissbook/ui/util/toaster'
import { Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import {
  MarketingForm,
  MarketingLayout,
  MaxWidthContainer,
  Section,
  Typography,
  customerImagesFolder,
} from '../components'

const fieldClassName = 'tw-mb-4 lg:tw-mb-8'

const validationSchema = Yup.object().shape({
  comments: Yup.string(),
  email: Yup.string().required().email().companyEmail(),
  employeesCount: Yup.number().required(),
  name: Yup.string().required(),
  newsletter: Yup.boolean(),
  organizationName: Yup.string().required(),
})

const formatMessage = ({ employeesCount, comments }) => {
  let message = `Hello, I'm interested in a Blissbook demo. My company has ${employeesCount} employees.`

  if (comments) {
    message += ` When asked if I had questions or comments, I wrote:\n\n${comments}`
  }

  return message
}

const onSubmit = async (values, { setStatus }) => {
  try {
    const { email, employeesCount, name, newsletter, organizationName } = values
    const body = formatMessage(values)
    const tag = 'Requested Demo'
    await submitForm({
      body,
      email,
      employeesCount,
      name,
      newsletter,
      organizationName,
      tag,
    })
    addToast(`Thanks! We'll get back to you as soon as we can.`, {
      type: 'success',
    })
    setStatus({ hasSubmitted: true })

    // HubSpot
    if (window._hsq) {
      window._hsq.push([
        'identify',
        {
          body,
          email,
          employeesCount,
          name,
          organizationName,
          tag,
        },
      ])
      window._hsq.push(['trackEvent', { id: 'Demo Requested' }])
    }
  } catch (error) {
    handleError(error, 'There was an error sending your message.')
  }
}

const WedgeItem = ({ children, color }) => (
  <div className='tw-flex'>
    <div className='tw-mr-4'>
      <Shape
        type='wedge'
        color={getColor(color)}
        fill
        size={20}
        rotate={180}
        css={{ marginTop: 6 }}
      />
    </div>
    <Typography variant='p1' css={{ flex: 1 }}>
      {children}
    </Typography>
  </div>
)

const LOGOS = [
  `${customerImagesFolder}/cengage/cengage-logo.png`,
  `${customerImagesFolder}/wd40/wd40-logo.png`,
  `${customerImagesFolder}/indigo/indigo-logo.png`,
  `${customerImagesFolder}/swbc/swbc-logo.svg`,
  `${customerImagesFolder}/accolade/accolade-logo.png`,
  `${customerImagesFolder}/kipp-nj/kipp-nj-logo.svg`,
]

export default () => (
  <MarketingLayout
    title={'Demo Blissbook’s Employee Handbook & Policy Management Software'}
    description={
      'Help us learn about you, your company, and your employee handbook project, then get a demo of Blissbook’s online employee handbook software.'
    }
  >
    <Section className='container py-lg'>
      <BackgroundShapeGrid
        alignX='left'
        alignY='top'
        className='tw-hidden md:tw-block'
        shapeDefaults={{ fill: true }}
        shapes={[
          { x: -1, y: 2, type: 'wedge', color: 'blurple-500', rotate: 180 },
          { x: -1, y: 3, type: 'square', color: 'aqua-700' },
          {
            x: 0,
            y: 3,
            type: 'square',
            color: 'aqua-500',
            className: 'tw-hidden lg:tw-block',
          },
          { x: -1, y: 4, type: 'wedge', color: 'blurple-700' },
          { x: -1, y: 6, type: 'square', color: 'blurple-300' },
          { x: -1, y: 7, type: 'square', color: 'purple-500' },
          { x: 0, y: 7, type: 'wedge', color: 'purple-300', rotate: 180 },
          { x: 0, y: 8, type: 'square', color: 'red-500' },
          { x: 0, y: 9, type: 'square', color: 'red-300' },
          { x: -1, y: 10, type: 'square', color: 'sunshine-700' },
        ]}
      />

      <BackgroundShapeGrid
        alignX='right'
        alignY='top'
        className='tw-hidden md:tw-block xl:tw-hidden'
        shapeDefaults={{ fill: true }}
        shapes={[
          { x: -1, y: 3, type: 'square', color: 'sunshine-700' },
          { x: -1, y: 4, type: 'wedge', color: 'sunshine-500' },
        ]}
      />

      <BackgroundShapeGrid
        alignX='right'
        alignY='top'
        className='tw-hidden xl:tw-block'
        shapeDefaults={{ fill: true }}
        shapes={[
          { x: 0, y: 3, type: 'square', color: 'sunshine-700' },
          { x: 0, y: 4, type: 'wedge', color: 'sunshine-500' },
        ]}
      />

      <Typography variant='h4' className='text-center tw-mx-auto'>
        Request a Demo
      </Typography>

      <Typography
        variant='h1'
        className='text-center tw-mx-auto tw-my-10'
        style={{ maxWidth: 550 }}
      >
        Want to see Blissbook in action?
      </Typography>

      <MaxWidthContainer className='tw-mx-auto' xs={960}>
        <Row className='tw-flex-row-reverse my-sm'>
          <Col xs={12} lg={6}>
            <MaxWidthContainer className='tw-mx-auto tw-mb-6' md={540} lg={390}>
              <Typography variant='h2'>We just need a bit of info.</Typography>

              <WedgeItem color='sunshine-500'>
                We’ll respond within 1 business day to schedule a call or demo
                based on your needs.
              </WedgeItem>

              <WedgeItem color='blurple-500'>
                We want to learn about you and your project before the demo.
              </WedgeItem>

              <WedgeItem color='aqua-500'>
                We do screen sharing with Zoom.
              </WedgeItem>

              <WedgeItem color='purple-500'>
                Feel free to invite additional team members!
              </WedgeItem>
            </MaxWidthContainer>
          </Col>

          <Col xs={12} lg={6}>
            <Formik
              initialValues={{
                comments: undefined,
                email: undefined,
                name: undefined,
                newsletter: true,
                organizationName: undefined,
                employeesCount: undefined,
              }}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {({
                handleSubmit,
                isSubmitting,
                status: { hasSubmitted } = {},
              }) => (
                <MarketingForm
                  noValidate
                  className='box-shadow tw-p-6'
                  css={{ background: 'white', borderRadius: 5 }}
                  onSubmit={handleSubmit}
                >
                  <fieldset disabled={isSubmitting || hasSubmitted}>
                    <Field
                      className={fieldClassName}
                      label='Name'
                      name='name'
                      placeholder='Leslie Knope'
                    />
                    <Field
                      className={fieldClassName}
                      label='Name of your Organization'
                      name='organizationName'
                      placeholder='Your Organization'
                    />
                    <Field
                      className={fieldClassName}
                      label='Work Email Address'
                      name='email'
                      placeholder='you@company.com'
                      type='email'
                    />
                    <Field
                      type='number'
                      className={fieldClassName}
                      inputStyle={{ maxWidth: 120 }}
                      min={1}
                      name='employeesCount'
                      label='Number of Employees at your Organization'
                      placeholder='####'
                    />
                    <Field
                      type='textarea'
                      className={fieldClassName}
                      label='Any Questions or Comments?'
                      minRows={3}
                      name='comments'
                      placeholder={`What's on your mind?`}
                    />
                    <Field
                      className={fieldClassName}
                      label='Join our community: Blissbook product news & more'
                      name='newsletter'
                      type='checkbox'
                    />

                    <Button
                      type='submit'
                      color={hasSubmitted ? 'success' : 'primary'}
                    >
                      {hasSubmitted
                        ? 'We received your Demo Request!'
                        : 'Submit Demo Request'}
                    </Button>
                  </fieldset>
                </MarketingForm>
              )}
            </Formik>
          </Col>
        </Row>

        <Typography
          variant='h2'
          className='text-center tw-mx-auto tw-my-18'
          style={{ maxWidth: 650 }}
        >
          We’re thankful we get to help thousands of great organizations inspire
          their employees.
        </Typography>

        <Row className='tw-justify-items-center'>
          {LOGOS.map((logo) => (
            <Col
              key={logo}
              xs={6}
              sm={4}
              lg={2}
              className='tw-flex tw-items-center tw-justify-center tw-mb-6'
            >
              <BackgroundImage
                src={logo}
                size='contain'
                css={{
                  filter: 'grayscale(100%)',
                  height: 50,
                  maxWidth: '100%',
                  opacity: 0.5,
                }}
              />
            </Col>
          ))}
        </Row>
      </MaxWidthContainer>
    </Section>
  </MarketingLayout>
)
