import dropRight from 'lodash/dropRight'

// domain ---------------------------------------------------------------------

// From https://github.com/colinhacks/zod/pull/3692
// This regex validates the domain name contains a TLD
const domainNameRegex =
  /^(?!-)(?!.*--)(?!.*\.\.)(?!.*\.$)[a-zA-Z0-9-]{1,63}(?<!-)(\.[a-zA-Z0-9-]{1,63})+$/
const punycodeRegex = /^xn--[a-zA-Z0-9-]{1,63}\.[a-zA-Z]{2,}$/ // Ensure TLD after punycode
// const ipv4Regex =
//   /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/
// const ipv6Regex = /^\[[0-9a-fA-F:]+\]$/

export function isValidDomain(domain: string) {
  return (
    domainNameRegex.test(domain) ||
    // We don't support ipv4 or ipv6 yet if it comes to be asked let's validate let's encrypt with it and the logic will be present
    //    ipv4Regex.test(domain) ||
    //    ipv6Regex.test(domain) ||
    punycodeRegex.test(domain)
  )
}

export function validateDomain(domain: string) {
  if (!isValidDomain(domain)) throw new TypeError(`Invalid domain: ${domain}`)
}

export const formatDomain = (domain: string) => {
  domain = domain.trim().toLowerCase()
  return domain
}

// subdomain ------------------------------------------------------------------

// Is not exactly true but this is what we accept for the moment
export const subdomainRegEx = /^[a-z0-9_-]+$/
export const isValidSubdomain = (subdomain: string) =>
  subdomainRegEx.test(subdomain)
export const validateSubdomain = (domain: string) => {
  if (!isValidSubdomain(domain))
    throw new TypeError(`Invalid subdomain: ${domain}`)
}

export const formatSubdomain = (subdomain: string) => {
  subdomain = subdomain.trim().toLowerCase()
  subdomain = subdomain.replace(/[\s@.]+/g, '-')
  subdomain = subdomain.replace(/[^A-Za-z0-9_-]+/g, '')
  return subdomain
}

export function getSubdomain(domain: string) {
  const parts = dropRight(domain.split('.'), 2)
  if (!parts.length) return
  return parts.join('.')
}
