import isString from 'lodash/isString'
import { type ColumnKey, isColumnKeyEqual } from '../properties'

export type SortDirection = 'asc' | 'desc'

export type SortOrder = ColumnKey & {
  direction: SortDirection
}

export function parseSortOrder(
  value: SortOrder | string,
): SortOrder | undefined {
  if (!value) return

  if (!isString(value)) {
    return {
      direction: value.direction,
      field: value.field,
      path: value.path,
    }
  }

  if (value[0] === '-') {
    return {
      direction: 'desc',
      field: value.slice(1),
      path: undefined,
    }
  }

  return {
    direction: 'asc',
    field: value,
    path: undefined,
  }
}

export function isSortOrderEqual(
  a: SortOrder | undefined,
  b: SortOrder | undefined,
): boolean {
  if (!a || !b) return a === b
  return isColumnKeyEqual(a, b) && a.direction === b.direction
}

export function stringifySortOrder(order: SortOrder) {
  const { field, direction } = order
  return direction === 'desc' ? `-${field}` : field
}

export function getNextSortOrder(
  columnOrder: SortOrder,
  order: SortOrder | undefined,
  canRemove?: boolean,
): SortOrder | undefined {
  if (!order) return columnOrder

  // If not on our field, move to the default sort order
  if (columnOrder.field !== order.field || columnOrder.path !== order.path) {
    return columnOrder
  }

  // If the same direction (or we cannot remove the sort), switch the direction
  if (!canRemove || columnOrder.direction === order.direction) {
    return {
      ...order,
      direction: order.direction === 'asc' ? 'desc' : 'asc',
    }
  }

  // If we can remove the sort, remove it
}
