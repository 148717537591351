import { Link } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment } from 'react'

import { MarketingLayout, Section, Typography } from '../components'

import { TOCList } from './TOC'
import {
  CaseStudy,
  ChapterHeader,
  ExternalLink,
  GetStartedHero,
  HowToSection,
  PersonCard,
} from './components'

import { images, urls } from './howToConfig'

import './styles/how-to-ebook.scss'

export default () => (
  <MarketingLayout
    title='Policy and Employee Handbook Design: The Pep Work'
    description={
      'Make your policies and procedures or employee handbook interactive and employee-friendly with this guide to on-brand handbook design.'
    }
    className='how-to-ebook'
  >
    <ChapterHeader
      title='5: The Pep Work'
      subtitle='Designing Your Policies and Handbook'
      prev={{ url: urls.yepWork, text: '4. The Yep Work' }}
      next={{ url: urls.acceptWork, text: '6. The Accept Work' }}
      img={images.headerPepWork.src}
    />

    <HowToSection
      className='container tw-mt-14'
      main={
        <Fragment>
          <Typography variant='h3' className='dark'>
            Now Comes The Fun Part. Hip-Hip-Hooray!
          </Typography>
          <Typography variant='p2'>
            You’ve put in all the hard work developing your policies and
            procedures content. Now you can focus on making your handbook look
            and feel as much a part of your company as the employees who show up
            every day because they believe in your mission. By focusing on
            design, you’ll make your employees feel how much they matter to your
            company. Good, easy-to-digest design also shows potential judges or
            jurors just how important your company policies are and how you’ve
            done everything you could to make it as easy as possible for an
            employee to read and understand them.
          </Typography>
        </Fragment>
      }
      side={<TOCList activeIndex={5} />}
    />

    <CaseStudy title='Case Study: Utilizing Your Marketing Department'>
      <Typography variant='p2'>
        Chanin saw the importance of communicating the company brand to
        prospective employees because low unemployment was making companies more
        competitive. She enlisted the help of their marketing department so the
        company could be more appealing to applicants within the highly
        competitive Fintech industry.
      </Typography>
      <PersonCard
        img={images.profileChanin.src}
        name='Chanin Christensen'
        title={
          <span>
            SVP / HR Manager at{' '}
            <ExternalLink href='https://merrickbank.com/'>
              Merrick Bank
            </ExternalLink>
          </span>
        }
      />
    </CaseStudy>

    <HowToSection
      className='container tw-mt-18'
      main={
        <Fragment>
          <Typography variant='p2'>
            This is when the earlier goodwill you earned from your marketing
            team can pay off, particularly if they’re already prepared to
            support the project.
          </Typography>
          <Typography variant='h3' className='dark'>
            Here are a few ways you can work with them:
          </Typography>
          <ul className='p2'>
            <li>
              Engage your designers and challenge them to find creative ways to
              break up blocks of text.
            </li>
            <li>
              Ask them to find ways to communicate visually to replace copy.
            </li>
            <li>
              Have them identify text they don’t understand or find boring.
            </li>
            <li>
              Ask for suggestions on how to make your policies better. They are
              supporters, not critics; their specialized input can take your
              handbook to the next level.
            </li>
          </ul>
          <Typography variant='p2'>
            If you had the help of one of their copywriters during the drafting
            phase, great. If not, see if you can get help now to build a better
            narrative that takes readers from your culture to your policies. A
            copywriter can also provide suggestions on headlines that reflect
            your company’s personality while grabbing the reader’s attention.
            You don’t need to rewrite all of your policies – in most cases,
            sprucing up the introductions is enough. A little pep goes a long
            way.
          </Typography>
          <Typography variant='p2'>
            The design phase is where you can take a step back from hard work of
            protecting the company and supporting employees. Now the focus
            shifts to how employees experience your policies. While you continue
            to navigate and negotiate your way through the remaining content
            approvals, have your designers and writers make your handbook easy
            on the eyes.
          </Typography>
          <Typography variant='p2'>
            If you’re using a policy management platform or software provider to
            host and distribute your handbook, ask for help with design and
            copywriting. If they’re like Blissbook, they’ll be able to provide
            this as a service. Because they focus on these projects all the
            time, they’ll be able to go faster than your marketing department
            (think 3 weeks instead of 3 months).
          </Typography>
        </Fragment>
      }
    />

    <HowToSection className='container' main={<GetStartedHero />} />

    <HowToSection
      className='container tw-mt-6'
      main={
        <Fragment>
          <Typography variant='h3' className='dark'>
            A Couple Photo Note-Ohs
          </Typography>
          <Typography variant='p2'>
            If you choose to use photos of your employees,{' '}
            <strong>get a photo release</strong> from each person. You don’t
            want to be forced to review and update your handbook every time an
            employee leaves the company.
          </Typography>
          <Typography variant='p2'>
            Although custom photography is a great way to connect with your
            audience, we know this might not always be in the budget. In that
            case, there are some great free stock photography options out there:
          </Typography>
          <ul className='p2'>
            <li>
              <ExternalLink href='https://unsplash.com/'>
                www.unsplash.com
              </ExternalLink>
            </li>
            <li>
              <ExternalLink href='https://www.pixabay.com'>
                www.pixabay.com
              </ExternalLink>
            </li>
            <li>
              <ExternalLink href='https://www.pexels.com'>
                www.pexels.com
              </ExternalLink>
            </li>
            <li>
              <ExternalLink href='https://www.pxhere.com'>
                www.pxhere.com
              </ExternalLink>
            </li>
            <li>
              <ExternalLink href='https://affecttheverb.com/collection/'>
                www.affecttheverb.com
              </ExternalLink>
            </li>
          </ul>
          <Typography variant='p2'>
            As they say, a well-placed picture can break up a thousand words.
          </Typography>
        </Fragment>
      }
    />

    <Section className='container tw-p-18 text-center'>
      <Fragment>
        <Typography variant='h2'>Next Chapter: The Accept Work</Typography>
        <Typography variant='p2'>
          <Link className='btn btn-link tw-m-2' href={urls.yepWork}>
            <FontAwesomeIcon className='tw-mr-2' icon='arrow-left' />
            Previous
          </Link>
          <Link className='btn btn-primary tw-m-2' href={urls.acceptWork}>
            Next: Policy Distribution and Getting Signatures
          </Link>
        </Typography>
      </Fragment>
    </Section>
  </MarketingLayout>
)
