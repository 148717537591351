import pick from 'lodash/pick'

export type ColumnKey<KeyType = string> = {
  field: KeyType
  path?: string | null
}

export function pickColumnKey<KeyType = string>(
  column: ColumnKey<KeyType>,
): ColumnKey<KeyType> {
  return pick(column, ['field', 'path'])
}

export function stringifyColumnKey<KeyType>(value: ColumnKey<KeyType>): string {
  const { field, path } = value
  return path ? `${field}.${path}` : field.toString()
}

export function isColumnKeyEqual<KeyType>(
  lhs: ColumnKey<KeyType>,
  rhs: ColumnKey<KeyType>,
) {
  const isKeyEqual = lhs.field === rhs.field
  const isPathEqual = lhs.path ? lhs.path === rhs.path : !rhs.path
  return isKeyEqual && isPathEqual
}
