import { colors, gridBreakpoints } from '@blissbook/ui/branding'
import { Col, Container, ForwardLink, Link, Row } from '@blissbook/ui/lib'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import tinycolor from 'tinycolor2'
import {
  HeadingDivider,
  MarketingLayout,
  MaxWidthContainer,
  Section,
  Typography,
} from '../components'
import { CallToActionSection, SolutionSection } from './sections'

const backgroundColor = tinycolor(colors['red-50']).setAlpha(0.5).toRgbString()
const color = 'red-500'
const iconPadding = 30

const FEATURES = [
  {
    icon: ['far', 'ban'],
    title: 'Say Nothing',
    description: `Publish new content any time, as often as you want. You don't need to tell a soul or force anyone to sign another form. Employees see your fresh content the next time they view the document.`,
  },
  {
    icon: ['far', 'sticky-note'],
    title: 'Annotate',
    description:
      'Never create a “summary of changes” bullet list again! Attach digital sticky notes to changes to provide context right alongside new or updated content. Annotations can be for your audience or for fellow collaborators.',
  },
  {
    icon: ['far', 'envelope'],
    title: 'Send an Email',
    description: `Big policy change going out? Need to remind people your handbook exists? Add an email to your annotations. All you have to say is, “When you open your handbook, you'll be guided through the changes.”`,
  },
]

const FEATURES_LARGE = [
  {
    icon: ['fal', 'robot'],
    title: 'Automatically invite new employees.',
    description: `Whether you're launching a new handbook or onboarding a new employee, you want people to know! When you synchronize your employee list automatically, invites can be auto-sent to new employees too.`,
  },
  {
    icon: ['fal', 'bell-exclamation'],
    title: 'Remind people who need to sign.',
    description: `Stay on top of employees with 1-click reminders or eliminate the busy work by setting up automated reminders that Blissbook sends on your behalf. You can even CC employees' managers!`,
  },
  {
    icon: ['fal', 'shield-check'],
    title: 'Trusted and not suspicious.',
    description: `Blissbook is 100% white-labelable. Employees trust Blissbook because everything looks and feels like it's coming from you. Set up a custom sending email account and design emails to reflect your brand.`,
  },
]

export default () => (
  <MarketingLayout
    title='Policy and Employee Handbook Communication Made Easy – Blissbook'
    description={
      'Easy communicate company policies with an online, digital employee handbook. Use a custom email account to send employees notifications of changes.'
    }
  >
    <SolutionSection
      color={color}
      image='Chat.svg'
      imageDesktopCss={css(`
        top: 90px;
        right: -90px;
        height: 363px;

        @media (min-width: ${gridBreakpoints.xl}px) {
          right: -60px;
        }
      `)}
      imageMobileCss={css(`
        transform: scaleX(-1);
      `)}
      greeting='Keep People Informed'
      title='Communicate with your audience.'
      description='What good are company policies if you don’t tell employees about them? Email sent through Blissbook is tracked in your documentation center, so even if an employee ignores your requests to view and sign, you’ll always be able to prove you told them about it.'
    />

    <Section>
      <Container className='py-sm'>
        <MaxWidthContainer className='tw-mx-auto' xs={960}>
          <Row className='tw-flex-row-reverse tw-items-center'>
            <Col lg={6} className='offset-lg-1 tw-mb-6 text-center'>
              <img
                alt='notification example'
                className='tw-p-2 tw-mb-8 box-shadow'
                src='/images/marketing/resources/screens/notification.png'
                style={{
                  width: '450px',
                  maxWidth: '100%',
                  borderRadius: 5,
                }}
              />
            </Col>
            <Col lg={5} className='tw-mb-6'>
              <HeadingDivider variant='h2' divider={{ color }}>
                Communicating Changes
              </HeadingDivider>
              <Typography variant='p2'>
                When you update policies, you’re in control of how to
                communicate changes with employees.
              </Typography>
            </Col>
          </Row>
        </MaxWidthContainer>
      </Container>
    </Section>

    <Section>
      <Container className='py-sm'>
        <MaxWidthContainer className='tw-mx-auto' xs={960}>
          <Row>
            {FEATURES.map((feature, index) => (
              <Col key={index} lg={4} className='tw-mb-6 tw-pr-10'>
                <FontAwesomeIcon
                  icon={feature.icon}
                  className='tw-mr-4 tw-mb-8'
                  color={colors[color]}
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: -2,
                    fontSize: 22,
                  }}
                />
                <Typography
                  variant='h4'
                  style={{ color, paddingLeft: iconPadding }}
                >
                  {feature.title}
                </Typography>
                <Typography variant='p2' style={{ paddingLeft: iconPadding }}>
                  {feature.description}
                </Typography>
              </Col>
            ))}
          </Row>
        </MaxWidthContainer>
      </Container>
    </Section>

    <Section
      style={{
        background: `linear-gradient(180deg, white 0%, ${backgroundColor} 100%)`,
      }}
    >
      <Container className='py-sm'>
        <MaxWidthContainer className='tw-mx-auto' xs={960}>
          <Row>
            {FEATURES_LARGE.map((feature, index) => (
              <Col
                key={index}
                lg={6}
                className={`tw-mb-18 ${
                  index % 2 === 0 ? 'lg:tw-pr-10' : 'lg:tw-pl-10'
                }`}
              >
                <FontAwesomeIcon
                  icon={feature.icon}
                  className='tw-mr-4 tw-mb-8'
                  color={colors[color]}
                  style={{
                    fontSize: 80,
                  }}
                />
                <Typography variant='h2'>{feature.title}</Typography>
                <Typography variant='p2'>{feature.description}</Typography>
              </Col>
            ))}
          </Row>
        </MaxWidthContainer>
      </Container>
    </Section>

    <CallToActionSection
      title='Ready to keep employees informed?'
      description='We’re here for you! Get in touch so we can learn about you and your project. We can give a demo and set up a free trial as needed.'
      contentClassName=''
      includeLogos
    >
      <div className='md:tw-flex tw-items-center tw-mb-8'>
        <Link className='btn btn-primary tw-mr-6' to='/pricing'>
          Try it free
        </Link>
        <ForwardLink className='tw-block tw-my-6' to='/request-a-demo'>
          Request a demo
        </ForwardLink>
      </div>
    </CallToActionSection>
  </MarketingLayout>
)
