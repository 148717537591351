import type {
  DatePropertyValue,
  PropertyValueInput,
} from '@blissbook/lib/properties'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import type { PropertyValueEditorProps } from './PropertyValueEditor'

import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'

import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const minDate = new Date('2000-01-01')

function parseDate(str: string) {
  if (moment(str).isValid() && moment(str).year() >= minDate.getFullYear()) {
    return moment(str).toDate()
  }
  return null
}

export function DatePropertyValueEditor({
  onChange,
  value,
}: PropertyValueEditorProps & {
  onChange: (value: PropertyValueInput | null) => void
  value: DatePropertyValue | null
}) {
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    value?.date ? new Date(value.date) : null,
  )
  const [calendarView, setCalendarView] = useState<'date' | 'month' | 'year'>(
    'date',
  )

  useEffect(() => {
    setSelectedDate(value?.date ? new Date(value.date) : null)
  }, [value?.date])

  return (
    <DatePicker
      autoFocus
      selected={selectedDate}
      onChange={(date: Date | null) => {
        if (date === null) {
          setSelectedDate(null)
          onChange(null)
        }
      }}
      onChangeRaw={(e: React.ChangeEvent<HTMLInputElement> | undefined) => {
        if (!e?.target?.value) return

        const trimmedInput = e.target.value.trim()
        if (
          /^(?:(\d{4})[-/.](\d{1,2})[-/.](\d{1,2})|(\d{1,2})[-/.](\d{1,2})[-/.](\d{4}))$/.test(
            trimmedInput,
          )
        ) {
          const parsed = parseDate(trimmedInput)
          if (parsed) {
            setSelectedDate(parsed)
            onChange({ date: parsed.toISOString() })
          }
        }
      }}
      onSelect={(date: Date) => {
        setSelectedDate(date)
        if (calendarView === 'date') {
          onChange({ date: date.toISOString() })
        } else if (calendarView === 'month') {
          setCalendarView('year')
        } else {
          setCalendarView('date')
        }
      }}
      minDate={minDate}
      isClearable
      dateFormat='yyyy-MM-dd'
      placeholderText='Select date...'
      renderCustomHeader={({
        date,
        decreaseYear,
        decreaseMonth,
        increaseYear,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className='flex items-center justify-between px-2 py-2'>
          <button
            onClick={calendarView === 'year' ? decreaseYear : decreaseMonth}
            disabled={prevMonthButtonDisabled}
            type='button'
            className='p-1'
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>

          <div className='flex gap-1 calendar-view-buttons'>
            {(calendarView === 'date' || calendarView === 'month') && (
              <button
                onClick={() => {
                  setCalendarView(calendarView === 'date' ? 'month' : 'date')
                }}
                type='button'
                className='text-sm font-semibold calendar-view-button-month'
              >
                {date.toLocaleString('default', { month: 'long' })}
              </button>
            )}
            {(calendarView === 'date' || calendarView === 'year') && (
              <button
                onClick={() => {
                  setCalendarView(calendarView === 'date' ? 'year' : 'date')
                }}
                type='button'
                className='text-sm font-semibold calendar-view-button-year'
              >
                {date.getFullYear()}
              </button>
            )}
          </div>

          <button
            onClick={calendarView === 'year' ? increaseYear : increaseMonth}
            disabled={nextMonthButtonDisabled}
            type='button'
            className='p-1'
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      )}
      shouldCloseOnSelect={calendarView === 'date'}
      showMonthYearPicker={calendarView === 'month'}
      showYearPicker={calendarView === 'year'}
    />
  )
}
