import type {
  DatePropertyValue,
  NumberPropertyValue,
  PersonPropertyValue,
  Property,
  PropertyValueInput,
  SelectPropertyValue,
  TextPropertyValue,
} from '@blissbook/lib/properties'
import type { Person } from '@blissbook/ui/lib/expression'
import { handleError } from '@blissbook/ui/util/errors'
import { addToast } from '@blissbook/ui/util/toaster'
import React from 'react'
import type { UpdatePropertyFunction } from '../PropertyEditor'
import { DatePropertyValueEditor } from './DatePropertyValueEditor'
import { NumberPropertyValueEditor } from './NumberPropertyValueEditor'
import { PersonPropertyValueEditor } from './PersonPropertyValueEditor'
import { SelectPropertyValueEditor } from './SelectPropertyValueEditor'
import { TextPropertyValueEditor } from './TextPropertyValueEditor'

export type PropertyValueEditorProps = {
  searchPeople: (text: string) => Promise<Person[]>
}

export function PropertyValueEditor({
  onChange,
  updateProperty,
  value,
  ...props
}: PropertyValueEditorProps & {
  onChange: (value: PropertyValueInput | null) => Promise<void>
  property: Property
  value: PropertyValueInput | null
  updateProperty?: UpdatePropertyFunction
}) {
  async function handleChange(value: PropertyValueInput | null) {
    try {
      await onChange(value)
      addToast(`Updated "${property.label}"`, {
        type: 'success',
      })
    } catch (error) {
      handleError(error)
    }
  }

  const { property } = props
  switch (property.type) {
    case 'number':
      return (
        <NumberPropertyValueEditor
          {...props}
          initialValue={value as NumberPropertyValue}
          onChange={handleChange}
        />
      )
    case 'person':
      return (
        <PersonPropertyValueEditor
          {...props}
          onChange={handleChange}
          value={value as PersonPropertyValue}
        />
      )
    case 'select':
      return (
        <SelectPropertyValueEditor
          {...props}
          onChange={handleChange}
          value={value as SelectPropertyValue}
          updateProperty={updateProperty}
        />
      )
    case 'text':
      return (
        <TextPropertyValueEditor
          {...props}
          initialValue={value as TextPropertyValue}
          onChange={handleChange}
        />
      )
    case 'date':
      return (
        <DatePropertyValueEditor
          {...props}
          onChange={handleChange}
          value={value as DatePropertyValue}
        />
      )
  }

  return null
}
