import { Mark } from '@tiptap/core'

export const DeletedMark = Mark.create({
  name: 'deleted',

  parseHTML() {
    return [
      {
        tag: 'del',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['del', HTMLAttributes, 0]
  },
})
