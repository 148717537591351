import { formatDate } from '@blissbook/lib/date-fns'
import { getInitials } from '@blissbook/lib/name'
import type {
  DatePropertyValue,
  NumberPropertyValue,
  PersonPropertyValue,
  Property,
  PropertyValue,
  SelectPropertyValue,
  TextPropertyValue,
} from '@blissbook/lib/properties'
import { UserProfileView } from '@blissbook/ui/lib/profile'
import React from 'react'
import { PropertyOptionView } from './PropertyOptionView'

export const NO_PROPERTY_VALUE = <span className='tw-text-gray-400'>-</span>

function NumberPropertyValueView({
  value,
}: {
  value: NumberPropertyValue
}) {
  return <span>{value.number}</span>
}
function PersonPropertyValueView({
  value,
}: {
  value: PersonPropertyValue
}) {
  const { people } = value
  if (!people.length) return NO_PROPERTY_VALUE

  return (
    <div className='-tw-mr-2 -tw-mb-1 tw-inline-flex tw-items-center tw-whitespace-normal tw-max-w-full'>
      {people.map((person) => (
        <div
          className='tw-inline-flex tw-items-center tw-gap-1 tw-mr-2 tw-mb-1 tw-max-w-full'
          key={person.id}
        >
          <UserProfileView
            className='tw-text-sm'
            size={24}
            user={{
              avatarURL: person.avatarUrl,
              className: 'tw-bg-blurple-500',
              fullName: person.fullName,
              initials: getInitials(person.fullName),
              personId: person.id,
            }}
          />

          <span className='tw-flex-1 ellipsis' title={person.fullName}>
            {person.fullName}
          </span>
        </div>
      ))}
    </div>
  )
}

function SelectPropertyValueView({
  property,
  value,
}: {
  property: Property
  value: SelectPropertyValue
}) {
  const options = property.options.filter((option) =>
    value.ids.includes(option.id),
  )
  if (!options.length) return NO_PROPERTY_VALUE
  return (
    <div className='-tw-mr-1 -tw-mb-1 tw-whitespace-normal tw-max-w-full'>
      {options.map((option) => (
        <PropertyOptionView
          className='tw-mr-1 tw-mb-1 tw-max-w-full'
          key={option.id}
          option={option}
        />
      ))}
    </div>
  )
}

function TextPropertyValueView({
  value,
}: {
  value: TextPropertyValue
}) {
  return <span>{value.text}</span>
}

function DatePropertyValueView({
  value,
}: {
  value: DatePropertyValue
}) {
  if (!value.date) return NO_PROPERTY_VALUE
  return <span>{formatDate(new Date(value.date), 'YYYY-MM-DD')}</span>
}

export function PropertyValueView({
  property,
  value,
}: {
  property: Property
  value: PropertyValue | undefined
}) {
  if (!value) return NO_PROPERTY_VALUE

  switch (property.type) {
    case 'date':
      return <DatePropertyValueView value={value as DatePropertyValue} />
    case 'number':
      return <NumberPropertyValueView value={value as NumberPropertyValue} />
    case 'person':
      return <PersonPropertyValueView value={value as PersonPropertyValue} />
    case 'select':
      return (
        <SelectPropertyValueView
          property={property}
          value={value as SelectPropertyValue}
        />
      )
    case 'text':
      return <TextPropertyValueView value={value as TextPropertyValue} />
  }

  return null
}
