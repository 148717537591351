import React from 'react'
import { MarketingLayout } from '../../components'
import BlogSection from './BlogSection'
import LeadersSection from './LeadersSection'
import ProductSection from './ProductSection'
import ProtectSection from './ProtectSection'
import TestimonialsSection from './TestimonialsSection'
import WelcomeSection from './WelcomeSection'

export default () => (
  <MarketingLayout
    title='Blissbook: Online Employee Handbook & Policy Management Software'
    description={
      'Protect your company and show employees they’re valued with Blissbook’s culture-first policy management & online employee handbook software'
    }
  >
    <WelcomeSection />
    <ProtectSection />
    <ProductSection />
    <TestimonialsSection />
    <LeadersSection />
    <BlogSection />
  </MarketingLayout>
)
