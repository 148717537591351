import {
  BackgroundImage,
  BackgroundShapeGrid,
  Col,
  Row,
  gridShapeSize,
} from '@blissbook/ui/lib'
import React from 'react'
import { Typography, customerImagesFolder } from '../../components'
import HeroSection from './HeroSection'

const LOGOS = [
  `${customerImagesFolder}/yahoo/yahoo-logo.svg`,
  `${customerImagesFolder}/wd40/wd40-logo.png`,
  `${customerImagesFolder}/underarmor/underarmor-logo.svg`,
  `${customerImagesFolder}/unilever/unilever-logo.svg`,
  `${customerImagesFolder}/cengage/cengage-logo.png`,
  `${customerImagesFolder}/abb/abb-logo.svg`,
]

export default ({
  children,
  title,
  contentClassName = 'md:tw-flex tw-items-center',
  titleVariant = 'h1',
  includeLogos,
  description,
}) => (
  <HeroSection
    css={{ minHeight: gridShapeSize * 5 }}
    background={
      <BackgroundShapeGrid
        alignX='right'
        alignY='top'
        shapeDefaults={{ fill: true }}
        shapes={[
          { x: 0, y: 0, type: 'wedge', color: 'sunshine-500', rotate: 180 },
          { x: 1, y: 0, type: 'square', color: 'red-500' },

          { x: 0, y: 1, type: 'wedge', color: 'aqua-500' },
          { x: 1, y: 1, type: 'square', color: 'blurple-500' },

          { x: 1, y: 2, type: 'wedge', color: 'purple-500', rotate: 180 },
          { x: 2, y: 2, type: 'square', color: 'red-500' },

          { x: 1, y: 3, type: 'square', color: 'purple-300' },

          { x: 0, y: 4, type: 'square', color: 'purple-100' },
        ]}
      />
    }
  >
    <Typography variant={titleVariant}>{title}</Typography>
    <Typography variant='p1'>{description}</Typography>
    <div className={contentClassName}>
      {children}
      <If condition={includeLogos}>
        <Row className='tw-justify-items-center'>
          {LOGOS.map((logo) => (
            <Col
              key={logo}
              xs={6}
              sm={4}
              lg={2}
              className='tw-flex tw-items-center tw-justify-center tw-mb-6'
            >
              <BackgroundImage
                src={logo}
                size='contain'
                css={{
                  height: 50,
                  maxWidth: '100%',
                }}
              />
            </Col>
          ))}
        </Row>
      </If>
    </div>
  </HeroSection>
)
