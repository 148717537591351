// IMPORTANT: THIS FILE IS IN TWO PROJECTS. TO MODIFY THIS FILE:
// Go to the blissbook project
// Make changes
// Copy the changes to the blissbook-marketing project
import { useMemo } from 'react'

export type NavItem = {
  label: string
  href: string
  items?: NavItem[]
}

type GetNavItemsArgs = {
  blogUrl: string
}

function getNavItems({ blogUrl }: GetNavItemsArgs) {
  const learnMoreNavItems: NavItem[] = [
    {
      label: 'Solutions',
      href: '/employee-handbook-software',
      items: [
        {
          href: '/employee-handbook-software',
          label: 'Online employee handbook',
        },
        {
          href: '/enterprise-policy-management',
          label: 'Enterprise policy management',
        },
        {
          href: '/employee-handbook-hr-policy-content',
          label: 'HR policy content services',
        },
        {
          href: '/legal-compliance',
          label: 'Be audit-ready (SOC 2 or otherwise)',
        },
        {
          href: '/branded-employee-handbook',
          label: 'Branded employee handbook',
        },
        {
          href: '/employee-handbook-management',
          label: 'PEOs and agencies',
        },
      ],
    },
    {
      label: 'Capabilities',
      href: '/personalized-handbooks',
      items: [
        {
          href: '/personalized-handbooks',
          label: 'Personalized handbooks',
        },
        {
          href: '/policy-communication',
          label: 'Policy communication',
        },
        {
          href: '/manager-participation',
          label: 'Manager Participation',
        },
      ],
    },
    {
      label: 'Integrations',
      href: '/integrations',
    },
    {
      href: '/information-security',
      label: 'IT & Security',
    },
    {
      href: '/customers',
      label: 'Customers',
    },
    {
      href: '/about',
      label: 'About Us',
    },
  ]

  const learnMoreNav: NavItem = {
    label: 'Learn More',
    href: '/employee-handbook-software',

    items: learnMoreNavItems,
  }

  const pricingNav: NavItem = {
    href: '/pricing',
    label: 'Pricing',
  }

  const resourcesNav: NavItem = {
    label: 'Resources',
    href: blogUrl,
    items: [
      {
        href: blogUrl,
        label: 'Blog',
      },
      {
        href: '/request-a-demo',
        label: 'Request a Demo',
      },
      {
        href: '/employment-law-updates',
        label: 'Employment Law Updates',
      },
      {
        href: '/newsletter',
        label: 'Newsletter',
      },
      {
        href: '/how-to-write-policies-procedures',
        label: 'eBook: How to Write Policies & Procedures',
      },
      {
        href: '/how-to-write-a-culture-first-employee-handbook',
        label: 'eBook: How to Write a Culture-First Employee Handbook',
      },
      {
        href: '/awkward-hr-conversations',
        label: 'Awkward HR Conversations',
      },
    ],
  }

  const desktopNav = [learnMoreNav, pricingNav, resourcesNav]
  const mobileNav = [...learnMoreNavItems, pricingNav, resourcesNav]
  return { desktopNav, mobileNav }
}

export function useNavItems(args: GetNavItemsArgs) {
  const { blogUrl } = args
  return useMemo(() => getNavItems({ blogUrl }), [blogUrl])
}
