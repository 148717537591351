import type {
  PersonPropertyValue,
  Property,
  PropertyValueInput,
} from '@blissbook/lib/properties'
// @ts-ignore: WIP imports
import { SearchInput } from '@blissbook/ui/lib'
import React from 'react'
import { PropertyOptionView } from '../PropertyOptionView'
import type { PropertyValueEditorProps } from './PropertyValueEditor'

type PersonOption = {
  key: string
  label: string
  value: number
}

export function PersonPropertyValueEditor({
  onChange,
  property,
  searchPeople,
  value,
}: PropertyValueEditorProps & {
  onChange: (value: PropertyValueInput) => void
  property: Property
  value: PersonPropertyValue | null
}) {
  const { allowMultiple } = property
  const people = value?.people || []
  const canAdd = allowMultiple || !people.length
  const personIds = people.map((p) => p.id)

  async function getOptions(text: string) {
    const people = await searchPeople(text)
    return people
      .filter((person) => !personIds.includes(person.id))
      .map((person) => ({
        label: person.fullName,
        key: person.id,
        value: person.id,
      }))
  }

  function handleAddPerson(personId: number) {
    onChange({ personIds: [...personIds, personId] })
  }

  function handleRemovePerson(personId: number) {
    const newPersonIds = personIds.filter((id) => id !== personId)
    onChange(newPersonIds.length ? { personIds: newPersonIds } : null)
  }

  return (
    <div className='tw-inline-flex tw-gap-1 tw-flex-wrap'>
      {people.map((person) => (
        <PropertyOptionView
          className='tw-inline-flex tw-items-center tw-gap-1 tw-px-1 tw-bg-gray-200 tw-rounded tw-whitespace-nowrap'
          key={person.id}
          option={{
            label: person.fullName,
          }}
          onRemove={() => handleRemovePerson(person.id)}
        />
      ))}

      {canAdd && (
        <SearchInput
          autoFocus
          getOptions={getOptions}
          inputClassName='form-control-sm tw-border-none'
          key={personIds.join('-')}
          minLength={0}
          onSelect={(option: PersonOption) => {
            handleAddPerson(option.value)
          }}
          placeholder='Add person...'
          searchIcon={false}
          style={{ width: 280 }}
        />
      )}
    </div>
  )
}
