import { useResizeColumn } from '@blissbook/ui/hooks/useResizeColumn'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import React, { type CSSProperties, type ReactNode, useMemo } from 'react'
import { InfiniteContextLoading, InfiniteContextWaypoint } from '../infinite'
import { type ColumnState, pickColumnState } from '../properties'
import { SortHeader, type SortOrder } from '../sort'
import { HeadingDivider } from './layout'
import type { TableColumn } from './types'

type GetRowPropsFunction = (row: any) => any
type OnChangeColumnsFunction = (columnsState: ColumnState[]) => void
type OnChangeOrderFunction = (order: SortOrder) => void
type TableRowData = Record<string, any>
type RowKeyFunction = (row: TableRowData) => React.Key
type RowKeyInput = RowKeyFunction | React.Key

function calcProps<Props>(
  props: Props,
  getProps: (props: Props) => Props,
): Props {
  return !getProps ? props : { ...props, ...getProps(props) }
}

type TableHeaderProps = {
  className?: string
  columns: TableColumn[]
  onChangeColumns?: OnChangeColumnsFunction
  onChangeOrder?: OnChangeOrderFunction
  order?: SortOrder
  style?: CSSProperties
}

function TableHeaderComponent({
  className,
  style,
  ...rowProps
}: TableHeaderProps) {
  return (
    <div className={cx('table-header', className)} style={style}>
      <TableRow {...rowProps} CellComponent={TableHeadingCell} />
    </div>
  )
}

const TableHeader = React.memo(TableHeaderComponent)

type TableHeadingCellProps = {
  column: TableColumn
  columns: TableColumn[]
  index: number
  onChangeColumns?: OnChangeColumnsFunction
  onChangeOrder?: OnChangeOrderFunction
  order?: SortOrder
}

function TableHeadingCell({
  columns,
  onChangeColumns,
  onChangeOrder,
  order,
  ...props
}: TableHeadingCellProps) {
  const { column, index } = props
  const { resizable } = column

  const onChangeWidth = onChangeColumns
    ? (width: number) => {
        const columnState = columns.map(pickColumnState)
        columnState[index].width = width
        onChangeColumns(columnState)
      }
    : undefined

  const { setHeaderNode, setResizeNode } = useResizeColumn(onChangeWidth)

  // Sortable?
  const cellProps = !column.order
    ? undefined
    : {
        Component: SortHeader,
        columnOrder: column.order,
        onChangeOrder,
        order,
      }

  return (
    <>
      <TableCell
        {...cellProps}
        className={cx('table-heading', column.headerClassName)}
        column={column}
        ref={setHeaderNode}
      >
        {renderCell(column.Header, props)}
      </TableCell>
      {onChangeColumns && resizable && !column.hidden && (
        <HeadingDivider ref={setResizeNode} />
      )}
    </>
  )
}

// Determine the key for this row
function getRowKey(row: TableRowData, rowKey: RowKeyInput = 'id') {
  return isFunction(rowKey) ? rowKey(row) : row[rowKey]
}

type TableBodyProps = {
  columns: TableColumn[]
  getRowProps?: GetRowPropsFunction
  rowClassName?: string
  rowKey: RowKeyInput
  rows: TableRowData[]
  selectedRowKey?: number | string
}

function TableBody({
  rowClassName,
  rowKey,
  rows,
  selectedRowKey,
  ...rowProps
}: TableBodyProps) {
  return (
    <div className='table-body'>
      {rows.map((row, rowIndex) => {
        const key = getRowKey(row, rowKey) || rowIndex
        return (
          <TableRow
            {...rowProps}
            CellComponent={TableBodyCell}
            className={rowClassName}
            key={key}
            row={row}
            selected={key === selectedRowKey}
          />
        )
      })}

      <InfiniteContextLoading className='table-cell'>
        Loading
      </InfiniteContextLoading>

      <InfiniteContextWaypoint />
    </div>
  )
}

type TableBodyCellProps = {
  cellProps?: any
  column: TableColumn
}

function TableBodyCell(props: TableBodyCellProps) {
  const { column } = props
  return (
    <TableCell
      className={cx('table-cell', column.cellClassName)}
      column={column}
    >
      {renderCell(column.Cell, props)}
    </TableCell>
  )
}

type TableRowProps = {
  CellComponent: any
  className?: string
  columns: TableColumn[]
  css?: CSSProperties
  getRowProps?: GetRowPropsFunction
  onClickRow?: (row: TableRowData) => void
  row?: TableRowData
  selected?: boolean
  style?: CSSProperties
}

function TableRowComponent({
  className: rowClassName,
  getRowProps,
  ...props
}: TableRowProps) {
  const {
    CellComponent,
    className,
    columns,
    css,
    onClickRow,
    selected,
    style,
    ...cellProps
  } = calcProps<TableRowProps>(props, getRowProps)

  const { row } = props
  const canClickRow = Boolean(onClickRow && row)
  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: should fix
    <div
      className={cx(
        'table-row tw-group',
        { active: selected },
        rowClassName,
        className,
      )}
      css={{
        cursor: canClickRow ? 'pointer' : undefined,
        ...css,
      }}
      onClick={() => {
        if (canClickRow) onClickRow(row)
      }}
      style={style}
    >
      {columns.map((column, columnIndex) => (
        <CellComponent
          {...cellProps}
          column={column}
          columns={columns}
          index={columnIndex}
          key={columnIndex}
        />
      ))}

      <If condition={onClickRow !== undefined}>
        <CellComponent {...cellProps} column={clickRowColumn} />
      </If>
    </div>
  )
}

const TableRow = React.memo(TableRowComponent)

const renderCell = (Component: any, props: any) => (
  <Choose>
    <When condition={!Component} />
    <When condition={isString(Component)}>
      <span className='ellipsis'>{Component}</span>
    </When>
    <Otherwise>
      <Component {...props} />
    </Otherwise>
  </Choose>
)

type TableCellProps = {
  Component?: any
  children?: ReactNode
  className?: string
  column: TableColumn
}

const TableCell = React.forwardRef<HTMLDivElement, TableCellProps>(
  ({ Component = 'div', className, column, ...props }, ref) => {
    const { flex, hidden, maxWidth, minWidth = 0, width } = column
    if (hidden) return null
    return (
      <Component
        {...props}
        className={cx(className, column.className)}
        ref={ref}
        style={{
          flex,
          maxWidth,
          minWidth,
          width,
        }}
      />
    )
  },
)

export type TableProps = {
  className?: string
  columns: TableColumn[]
  getRowProps?: GetRowPropsFunction
  onChangeColumns?: OnChangeColumnsFunction
  onChangeOrder?: OnChangeOrderFunction
  onClickRow?: (row: TableRowData) => void
  order?: SortOrder
  selectedRowKey?: number | string
  rowClassName?: string
  rowKey?: RowKeyInput
  rows: TableRowData[]
  style?: CSSProperties
}

function TableComponent({
  className,
  getRowProps,
  onChangeColumns,
  onChangeOrder,
  order,
  rowClassName,
  rowKey,
  rows,
  selectedRowKey,
  style,
  ...props
}: TableProps) {
  const { columns } = props
  const hasHeader = useMemo(() => columns.some((col) => col.Header), [columns])

  return (
    <div className={cx('table', className)} style={style}>
      <If condition={hasHeader}>
        <TableHeader
          {...props}
          className={rowClassName}
          columns={columns}
          onChangeColumns={onChangeColumns}
          onChangeOrder={onChangeOrder}
          order={order}
        />
      </If>

      <TableBody
        {...props}
        getRowProps={getRowProps}
        rowClassName={rowClassName}
        rowKey={rowKey}
        rows={rows}
        selectedRowKey={selectedRowKey}
      />
    </div>
  )
}

export const Table = React.memo(TableComponent)

const clickRowColumn = {
  minWidth: 24,
  cellClassName: 'tw-flex-1 tw-justify-end tw-text-gray-300',
  Cell: () => (
    <FontAwesomeIcon css={{ fontSize: 12 }} icon={['far', 'chevron-right']} />
  ),
}
