import { gridBreakpoints } from '@blissbook/ui/branding'
import { Container, Link, ShapeConfetti } from '@blissbook/ui/lib'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment } from 'react'

import { MarketingLayout, Section, Typography } from '../components'

import { TOCImages, TOCList } from './TOC'
import { DownloadWorkbook, HowToSection, QuoteBordered } from './components'

import { images, urls } from './howToConfig'

import './styles/how-to-ebook.scss'

export default () => (
  <MarketingLayout
    title='How to Write Policies and Procedures: an eBook by Blissbook'
    description={
      'A complete how-to guide for writing policies and procedures or an employee handbook. Includes details on team collaboration, project management, technical writing, and distribution.'
    }
    className='how-to-ebook'
  >
    <Section id='how-to' className='py-sm'>
      <Container
        className='container tw-flex tw-flex-col tw-items-center'
        css={css(`
          position: relative;
          @media (min-width: ${gridBreakpoints.lg}px) {
            height: 400px;
          }
        `)}
      >
        <ShapeConfetti
          css={css(`
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
          `)}
          className='tw-hidden lg:tw-block'
          shapes={[
            {
              x: -550,
              y: -40,
              type: 'wedge',
              color: 'aqua-500',
              rotate: 180,
            },
            { x: -360, y: -30, type: 'square', color: 'red-500', fill: true },
            {
              x: -560,
              y: 50,
              type: 'quote',
              color: 'sunshine-500',
              fill: true,
            },
            {
              x: -580,
              y: 180,
              type: 'wedge',
              color: 'purple-500',
              fill: true,
            },
            { x: -470, y: 260, type: 'quote', color: 'aqua-500', fill: true },
            {
              x: -510,
              y: 340,
              type: 'wedge',
              color: 'sunshine-500',
              fill: false,
            },
            {
              x: -100,
              y: -40,
              type: 'square',
              color: 'blurple-500',
              fill: true,
            },
            {
              x: 80,
              y: -45,
              type: 'wedge',
              color: 'sunshine-500',
              fill: true,
              rotate: 180,
            },
            {
              x: 400,
              y: -45,
              type: 'wedge',
              color: 'aqua-500',
              fill: true,
              rotate: 270,
            },
            {
              x: 550,
              y: -10,
              type: 'wedge',
              color: 'purple-500',
              fill: false,
            },
            { x: 570, y: 90, type: 'quote', color: 'purple-500', fill: true },
            {
              x: 520,
              y: 180,
              type: 'square',
              color: 'red-500',
              rotate: 45,
              fill: true,
            },
            {
              x: 470,
              y: 310,
              type: 'square',
              color: 'blurple-500',
              fill: false,
            },
            {
              x: 560,
              y: 340,
              type: 'wedge',
              color: 'purple-500',
              fill: true,
            },
          ]}
          showCannonAnimation
          showOrbitAnimation
        />
        <Typography variant='h1' className='text-center'>
          How to Write Policies &amp; Procedures
        </Typography>
        <Typography
          variant='p1'
          className='text-center'
          style={{ maxWidth: '70%' }}
        >
          From team collaboration to technical writing and distribution, this is
          your complete how-to guide for company policies and procedures.
        </Typography>
        <img
          {...images.howToDesk}
          alt='Illustration of man and 2 women at desk working'
        />
        <Link
          href='/assets/how-to-write-policies-and-procedures.pdf'
          className='btn btn-sm btn-block btn-primary tw-mt-14 md:tw-hidden'
        >
          <FontAwesomeIcon icon='file-pdf' /> Download this eBook as a PDF
        </Link>
      </Container>
    </Section>

    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='h2'>
            Welcome to your Policies and Procedures journey!
          </Typography>
          <Typography variant='p2'>
            People: they’re your company’s best and most vital asset. As an HR
            pro, it’s your job to help attract, retain, and support top-notch
            people, all while protecting your company. Despite the angst that
            often accompanies a handbook overhaul, it’s possible to take care of
            policy business while showing your employees they’re valued. Buckle
            up, folks.
          </Typography>
          <Typography variant='p2'>
            This guide includes stories and expert advice from HR professionals
            and employment attorneys, all topped off with best practices for
            creating, championing, and communicating your policies and
            procedures.
          </Typography>
        </Fragment>
      }
      side={<TOCList activeIndex={0} />}
    />

    <HowToSection
      className='container'
      main={
        <QuoteBordered
          quote={
            'Great companies don’t hire skilled people and motivate them, they hire already motivated people and inspire them.'
          }
          attribution={{
            name: 'Simon Sinek',
            title:
              'Start With Why: How Great Companies Inspire Everyone To Take Action',
          }}
        />
      }
    />

    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='h3' className='dark'>
            In this policies and procedures guide, you’ll learn:
          </Typography>
          <ul className='p2'>
            <li>Who to involve in the process</li>
            <li>What resources you’ll need for success</li>
            <li>The steps to take with what people</li>
            <li>Tips for drafting, creation, reviewing, and printing</li>
            <li>How to acquire revisions and acceptance</li>
          </ul>
          <Typography variant='p2'>
            We hope that, with patience and dedication, you can transform your
            handbook from a potential irritant to a valuable instrument – one
            that helps onboard employees and keeps them on board.
          </Typography>
        </Fragment>
      }
    />

    <DownloadWorkbook
      extraMarginTop='-100px'
      extraMarginBottom='-60px'
      stack={[
        images.workbook.cover,
        images.workbook.commsStrategy,
        images.workbook.milestones1,
      ]}
    />

    <Section className='container'>
      <div className='col-md-8 tw-my-10'>
        <Typography variant='h3' className='dark'>
          Our policies and procedures guide is broken into six chapters:
        </Typography>
      </div>
      <TOCImages />
    </Section>

    <Section className='container tw-p-18 text-center'>
      <Fragment>
        <Typography variant='h2'>
          Let's get started with The Prep Work!
        </Typography>
        <Typography variant='p2'>
          <Link className='btn btn-primary tw-m-2' href={urls.prepWork}>
            First Chapter: Get Started
          </Link>
        </Typography>
      </Fragment>
    </Section>
  </MarketingLayout>
)
