import { BackgroundImage } from '@blissbook/ui/lib'
import React from 'react'
import { Section, customerImagesFolder } from '../../components'
import FeaturesContainer from './FeaturesContainer'

const FEATURES = [
  {
    heading: 'Start with why',
    title: 'Stay on-brand and culture-first.',
    description:
      'Help employees understand why they should care about your policies before they learn about them.',
    link: {
      to: '#employee-experience',
      children: 'See the Employee Experience',
    },
  },
  {
    heading: 'Designed for you',
    title: 'Get white-glove service from the Blissbook team.',
    description:
      'Send your content and branding info and we’ll create your interactive handbook for you. Training included!',
    link: {
      to: 'request-a-demo',
      children: 'Request a Quote',
    },
  },
  {
    heading: 'Comply with current laws',
    title: 'Create a compliant multi-state handbook.',
    description: `We'll help you build or revise your handbook to ensure your policies comply with the law in all states.`,
    link: {
      to: 'employee-handbook-hr-policy-content',
      children: 'HR Policy Content Services',
    },
  },
  {
    heading: 'Looks great on phones',
    title: 'Design for all devices (’cause it isn’t 1999).',
    description:
      'A responsive template ensures your handbook looks & works great on laptops, phones, tablets, and printers.',
  },
]

const HANDBOOKS = [
  {
    src: `${customerImagesFolder}/cengage/cengage-handbook.jpg`,
    top: 0,
    height: 240,
    right: 220,
    width: 200,
    backgroundPosition: '0 -560px',
    backgroundSize: '100% auto',
  },
  {
    src: `${customerImagesFolder}/swbc/swbc-handbook-chapter.jpg`,
    top: 0,
    height: 200,
    right: -120,
    width: 320,
    backgroundPosition: '0 -20px',
    backgroundSize: '100% auto',
  },
  {
    src: `${customerImagesFolder}/wd40/wd40-handbook.jpg`,
    top: 220,
    height: 160,
    right: 0,
    width: 200,
    backgroundPosition: '0 -634px',
    backgroundSize: '100% auto',
  },
  {
    src: `${customerImagesFolder}/caspers/caspers-handbook.jpg`,
    top: 220,
    height: 220,
    right: -320,
    width: 300,
    backgroundPosition: '-25px -660px',
    backgroundSize: '120% auto',
  },
]

const HandbookImage = ({ src, ...style }) => (
  <BackgroundImage
    css={{ position: 'absolute', zIndex: -1 }}
    className='tw-hidden lg:tw-block box-shadow'
    src={src}
    style={style}
  />
)

export default () => (
  <Section id='employee-handbook-builder'>
    <FeaturesContainer
      background={HANDBOOKS.map((props, index) => (
        <HandbookImage {...props} key={index} />
      ))}
      color='sunshine-500'
      features={FEATURES}
      headingColor='sunshine-700'
      shapeType='pen'
      title='Create.'
      titleOffset={2.5}
      topLeftShapes={[
        { x: 0, y: 1, type: 'square', color: 'sunshine-100', fill: true },
        { x: 1, y: 1, type: 'square', color: 'sunshine-300', fill: true },
        {
          x: 0,
          y: 2,
          type: 'wedge',
          color: 'sunshine-500',
          rotate: -90,
          fill: true,
          className: 'tw-hidden xl:tw-block',
        },
        {
          x: 0,
          y: 1,
          type: 'pen',
          color: 'sunshine-500',
          translateX: 5,
          translateY: -50,
        },
      ]}
    />
  </Section>
)
